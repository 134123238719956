/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { IPopup } from "../utils/defaultData";
import { sendAmplitudeData } from '../utils/amplitideUtils';
import { AMPLITUDE_EVENTS } from '../utils/amplitudeConstants';
interface IModalProps {
  handleClose: () => void;
  popup: IPopup;
}

const Modal = (props: IModalProps): JSX.Element => {
  const [played, setPlayed] = useState(0);
  const onError = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.ModalVideoError, {playTime: played, audioName: props.popup.audioName});
  }
  const onPlay = () => {
    sendAmplitudeData(AMPLITUDE_EVENTS.ModalVideoPlay, {playTime: played, audioName: props.popup.audioName});
  }
  const onPause =() => {
    sendAmplitudeData(AMPLITUDE_EVENTS.ModalVideoPause, {playTime: played, audioName: props.popup.audioName});
  }
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden px-6 h-full overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="relative overflow-hidden transition-all md:h-auto transform bg-white md:rounded-2xl sm:shadow-xl md:max-w-xl flex flex-col max-h-screen-resp  bg-brand-darkblue"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-brand-darkblue absolute top-0 z-10 bg-white w-full px-8 py-4 flex justify-between border-b border-brand-darkblue tex-white">
            <div className="text-white font-bold text-xl pt-2">{props.popup.heading}</div>
            <button
              className="text-gray-3 rounded-full focus:outline-none"
              onClick={() => {props.handleClose()
                sendAmplitudeData(AMPLITUDE_EVENTS.AudioPopupClosed, `player: ${played}`)
              }}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#ffffff"
              >
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
              </svg>
            </button>
          </div>
          <div className="overflow-y-scroll mt-6 mb-3 py-6 px-8">
            <div className="text-brand-grey pt-4 text-sm">
              {props.popup.description}
            </div>
            {props.popup.audioFile && (
              <div className="relative pt-6">
                <ReactPlayer
                  className="react-player"
                  url={props.popup.showVideo ? props.popup.videoFile : props.popup.audioFile}
                  controls
                  width="100%"
                  height={props.popup.showVideo ? "320px" : "60px"}
                  onProgress={(progress) => {
                    setPlayed(progress.playedSeconds);
                  }}
                  onError={onError}
                  onPlay={onPlay}
                  onPause={onPause}
                  onContextMenu={(e: React.SyntheticEvent) =>
                    e.preventDefault()
                  }
                  // Disable download button
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                />
                {!props.popup.showVideo && props.popup.audioName && (
                  <div className="text-xs font-bold absolute top-10 left-14">
                    {props.popup.audioName}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-white"></div>
    </>
  );
};

export default Modal;
