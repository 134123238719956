// routes constants
export const CONSTANTS = {
  PAYMENT_LINK: 'https://buy.stripe.com/6oE8wN04KeCC2fm8wD',
  SCHEDULE_CALL_LINK: 'https://meetings.hubspot.com/paridhi/mba-beyond-tool-review-content'
};

export const PHASE_TYPES = {
  standard: "standard",
  link: "link",
  static: "static",
};
