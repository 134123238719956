export interface StringMap {
  [key: string]: string;
}
const domain = process.env.REACT_APP_MBA_BEYOND_DOMAIN;

export const apiUrls: StringMap = {
  POST_SIGN_UP: `${domain}/auth/register`,
  POST_LOGIN: `${domain}/auth/login`,
  POST_FB_LOGIN: `${domain}/auth/facebook/login`,
  GET_DASHBOARD_DATA: `${domain}/me`,
  GET_DASHBOARD_PUBLIC_DATA: `${domain}/static/sections`,
  POST_ANSWER: `${domain}/answer/save`,
  POST_DIRECT_ANSWER: `${domain}/user/meta-question`,
  GET_ANSWERS: `${domain}/answers?profileDataId`,
  POST_STATUS: `${domain}/profile/status`,
  POST_UPLOAD_MEDIA: `${domain}/user/media`,
  POST_COMMENTS: `${domain}/comment/save-comment`,
  GET_COMMENTS: `${domain}/comment/get-comment`,
  POST_FORGET_PASSWORD: `${domain}/auth/forgot-password`,
  POST_RESET_PASSWORD: `${domain}/auth/reset-password`,
  POST_CONSULTANT_LOGIN: `${domain}/auth/login/consultant`,
  PUT_CONSULTANT_STATUS: `${domain}/consultant/u/status/update`,
  PUT_CONSULTANT_DEADLINE: `${domain}/consultant/u/deadline/update`,
  PUT_CONSULTANT_USER_ACTIVE: `${domain}/consultant/u/active/update`,
  GET_ALL_USERS: `${domain}/all/users`,
  POST_RESEND_VARIFICATION_REQUEST: `${domain}/auth/resend-email-verification`,
  GET_CONSULTANT_MAPPING: `${domain}/mapping/consultant`,
  GET_CONSULTANT_DOCUMENT: `${domain}/consultant/u/form-responses`,
  POST_SCHOOL_SAVE: `${domain}/user/schools/save`,
  POST_CONSULTANT_SCHOOL_SAVE: `${domain}/consultant/u/schools/save`,
  POST_HUBSPOT_DATA: `${domain}/fetch/u/hubspotdata`,
  GET_MILESTONE: `${domain}/fetch/u/milestone`,
  CREATE_MILESTONE: `${domain}/create/u/milestone`,
  CHECKOUT_INSTALLMENT: `${domain}/checkout/installments/dbbjbuljbJHBuygbigawenkkw`,
  POST_PROFILE_EXPERT: `${domain}/kjjjuisaunhk2401/add/profile-expert`,
  GET_PROFILE_EXPERT: `${domain}/kjjjuisaunhk2401/add/profile-expert`,
  PUT_PROFILE_EXPERT: `${domain}/kjjjuisaunhk2401/add/profile-expert`,
  POST_CHATGPT_LOGS: `${domain}/chatgpt/logs`,
  GET_USER_JOURNEY_DATA: `${domain}/userjourney`,
  POST_USER_JOURNEY_DATA: `${domain}/userjourney`,
  GET_FILTERED_MAPPING: `${domain}/v1/mapping/search`,
  GET_INTERVIEW_PREP_DATA:`${domain}/interviewPrepVideo`,
  POST_INTERVIEW_PREP_DATA:`${domain}/interviewPrepVideo`,
  POST_USER_INDUSTRY_DATA:`${domain}/userIndustry`
};
