import * as userConstants from "../constants";
import {
  ILoginRequest,
  ILoginSuccess,
  ILoginFail,
  ILogout,
  IRegisterFailure,
  IRegisterRequest,
  IRegisterSuccess,
  IPostForgetPasswordFailure,
  IPostForgetPasswordSuccess,
  IPostForgetPasswordRequest,
  IPostResetPasswordFailure,
  IPostResetPasswordRequest,
  IPostResetPasswordSuccess,
  IRefresh,
  IConsultantLoginFailure,
  IConsultantLoginRequest,
  IConsultantLoginSuccess,
} from "../actions/actionTypes";
import { initialState, IUser } from "../store/initialState";

export const user = (
  userState = initialState.user,
  action:
    | ILoginRequest
    | ILoginSuccess
    | ILoginFail
    | ILogout
    | IRegisterRequest
    | IRegisterFailure
    | IRegisterSuccess
    | IPostForgetPasswordFailure
    | IPostForgetPasswordSuccess
    | IPostForgetPasswordRequest
    | IPostResetPasswordFailure
    | IPostResetPasswordRequest
    | IPostForgetPasswordSuccess
    | IPostResetPasswordFailure
    | IPostResetPasswordSuccess
    | IRefresh
    | IConsultantLoginFailure
    | IConsultantLoginRequest
    | IConsultantLoginSuccess
): IUser => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        email: action.user.email,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: false,
        email: action.user?.email,
        profileId: action.user?.id || 0,
        name: action.user?.name || '',
      };
    case userConstants.LOGIN_FAILURE:
      return {
        loggedIn: false,
        loginError: action.error?.message || 'Not Found',
        emailVerified: action?.error?.emailVerified,
      };
    case userConstants.LOGOUT:
      return {};
    case userConstants.REGISTER_REQUEST:
      return {
        registering: true,
        email: action.user.email,
        
      };
    case userConstants.REGISTER_SUCCESS:
      return { 
        ...userState, 
        registering: false,
        profileDataId: action.user.data?.profileDataId || '',
        userProfileId: action.user.data?.userProfileId || '',
        id: action.user.data?.id || '',
        origin: action.user.data?.origin || '',
        originString: action.user.data?.originString || '',
        selectedSchools: action.user.data?.selectedSchools || [],
        name: action.user.data?.fullName || ''
      };
    case userConstants.REGISTER_FAILURE:
      return { registering: false, signinError: action.error?.message };
    case userConstants.POST_FORGET_PASSWORD_SUCCESS:
      return {
        ...userState,
        forgetPasswordSuccess:
          "Kindly check your email and follow the instructions",
      };
    case userConstants.POST_FORGET_PASSWORD_REQUEST:
      return {
        ...userState,
        email: action.email,
        forgetPasswordSuccess: undefined,
        forgetPasswordError: undefined,
      };
    case userConstants.POST_FORGET_PASSWORD_FAILURE:
      return {
        ...userState,
        forgetPasswordError: action.error?.message,
      };
    case userConstants.POST_RESET_PASSWORD_REQUEST:
      return {
        ...userState,
      };
    case userConstants.POST_RESET_PASSWORD_FAILURE:
      return {
        ...userState,
        resetPasswordError: action.error?.message,
      };
    case userConstants.CONSULTANT_LOGIN_REQUEST:
      return {
        loggingIn: true,
        email: action.user.email,
      };
    case userConstants.CONSULTANT_LOGIN_SUCCESS:
      return {
        loggedIn: false,
        email: action.user?.email,
        profileId: action.user?.id || 0,
        name: action.user?.name || '',
      };
    case userConstants.CONSULTANT_LOGIN_FAILURE:
      return {
        loggedIn: false,
        loginError: action.error?.message || 'Not Found',
      };
    case userConstants.REFRESH_SUCCESS:
      return {
        ...userState,
        resetPasswordError: undefined,
        forgetPasswordSuccess: undefined,
        forgetPasswordError: undefined,
        loggedIn: undefined,
        loginError: undefined,
        registering: undefined,
        signinError: undefined,
      };
    default:
      return { ...userState };
  }
};
