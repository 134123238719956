export const GET_ANSWERS_REQUEST = 'GET_ANSWERS_REQUEST';
export type GET_ANSWERS_REQUEST = typeof GET_ANSWERS_REQUEST;

export const GET_ANSWERS_SUCCESS = 'GET_ANSWERS_SUCCESS';
export type GET_ANSWERS_SUCCESS = typeof GET_ANSWERS_SUCCESS;

export const GET_ANSWERS_FAILURE = 'GET_ANSWERS_FAILURE';
export type GET_ANSWERS_FAILURE = typeof GET_ANSWERS_FAILURE;

export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS';
export type UPDATE_ANSWER_SUCCESS = typeof UPDATE_ANSWER_SUCCESS;