import React from "react";
import { sendAmplitudeData } from "../utils/amplitideUtils";
import { AMPLITUDE_EVENTS } from "../utils/amplitudeConstants";
import { CONSTANTS } from "../utils/Constants";

const PaymentCard = (): JSX.Element => {
  return (
    <>
      <div className="bg-brand-darkblue ml-10 mt-10 shadow-lg rounded-2xl bg-white p-4 hidden">
        <p className="text-white text-lg font-medium mb-2">Expert Review</p>
        <p className="text-white text-3xl font-bold hidden">
          $50
          <span className="text-gray-300 text-sm">/ month</span>
        </p>
        <p className="text-gray-100  text-xs mt-2">
          We are here with you throughout your MBA application journey. Schedule
          a call if you want to
        </p>
        <ul className="text-sm text-gray-100 w-full mt-4 mb-4 text-xs">
          <li className="mb-3 flex items-center text-xs">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2 text-brand-torquise"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Get your content reviewed
          </li>
          <li className="mb-3 flex items-center text-xs">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2 text-brand-torquise"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Evaluate your chances
          </li>
          <li className="mb-3 flex items-center text-xs">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2 text-brand-torquise"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Develop your stories
          </li>
        </ul>
        <a
          href={CONSTANTS.SCHEDULE_CALL_LINK}
          onClick={() => {
            sendAmplitudeData(AMPLITUDE_EVENTS.ScheduleCallCTA, {
              route: window.location.href,
              source: "sidecard",
            });
          }}
          target="_blank"
          className="inline-block py-2 px-4 bg-brand-torquise text-white w-full transition text-center text-sm shadow-md focus:outline-none rounded-3xl "
        >
          Schedule a call
        </a>
      </div>

      <div className="shadow-xl ml-10 mt-10 rounded-2xl bg-white dark:bg-gray-800 p-4">
        <p className="text-gray-800 text-lg font-medium mb-2">Expert Review</p>
        <p className="text-gray-900 text-3xl font-bold hidden">
          $50
          <span className="text-gray-300 text-sm">/ month</span>
        </p>
        <p className="text-gray-600   text-xs mt-2">
          We are here with you throughout your MBA application journey. Schedule
          a call if you want to
        </p>
        <ul className="text-sm text-gray-600 w-full mt-4 mb-4">
          <li className="mb-3 flex items-center text-xs">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2 text-brand-torquise"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Get your content reviewed
          </li>
          <li className="mb-3 flex items-center text-xs">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2 text-brand-torquise"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Evaluate your chances
          </li>
          <li className="mb-3 flex items-center text-xs">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2 text-brand-torquise"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Develop your stories
          </li>
        </ul>
        <a
          href={CONSTANTS.SCHEDULE_CALL_LINK}
          onClick={() => {
            sendAmplitudeData(AMPLITUDE_EVENTS.ScheduleCallCTA, {
              route: window.location.href,
              source: "sidecard",
            });
          }}
          target="_blank"
          className="inline-block py-2 px-4 bg-brand-torquise text-white w-full transition text-center text-sm shadow-md focus:outline-none rounded-3xl "
        >
          Schedule a call
        </a>
      </div>

      <div className="shadow-xl ml-10 mt-10 rounded-2xl border border-1 bg-white dark:bg-gray-800 p-4 hidden">
        <p className="text-gray-800 text-lg font-medium mb-2">Expert Review</p>
        <p className="text-gray-900 text-3xl font-bold hidden">
          $50
          <span className="text-gray-300 text-sm">/ month</span>
        </p>
        <p className="text-gray-600   text-xs mt-2">
          We are here with you throughout your MBA application journey. Schedule
          a call if you want to
        </p>
        <ul className="text-sm text-gray-600 w-full mt-4 mb-4">
          <li className="mb-3 flex items-center text-xs">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2 text-brand-torquise"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Get your content reviewed
          </li>
          <li className="mb-3 flex items-center text-xs">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2 text-brand-torquise"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Evaluate your chances
          </li>
          <li className="mb-3 flex items-center text-xs">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 mr-2 text-brand-torquise"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            Develop your stories
          </li>
        </ul>
        <a
          href={CONSTANTS.SCHEDULE_CALL_LINK}
          onClick={() => {
            sendAmplitudeData(AMPLITUDE_EVENTS.ScheduleCallCTA, {
              route: window.location.href,
              source: "sidecard",
            });
          }}
          target="_blank"
          className="inline-block py-2 px-4 bg-brand-torquise text-white w-full transition text-center text-sm shadow-md focus:outline-none rounded-3xl "
        >
          Schedule a call
        </a>
      </div>

    </>
  );
};

export default PaymentCard;
