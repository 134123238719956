import React, { useState } from "react";
import { createPopper } from "@popperjs/core";
import { useSelector } from "react-redux";
import { InitialStore, Source } from "../store/initialState";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../utils/routeConstant";
import ChangeStatusModal from "./ChangeStatusModal";
import ChangeDeadlineModal from "./ChangeDeadlineModal";
import ApplicantProfileModal from "./ApplicantProfileModal"
import ChatgptModal from "./ChatgptModal";
import { sendAmplitudeData } from "../utils/amplitideUtils";
import { AMPLITUDE_EVENTS } from "../utils/amplitudeConstants";

const UserDropdown: React.FC<any> = (props): JSX.Element => {
  const { showButton }  = props;
  const history = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeadlineModal, setOpenDeadlineModal] = useState<boolean>(false);
  const [openUserProfileModal, setOpenUserProfileModal] = useState<boolean>(false);
  const userProfile = useSelector((state: InitialStore) => state?.userProfile);
  const user = useSelector((state: InitialStore) => state.user)
  const [popoverShow, setPopoverShow] = React.useState(false);
  const btnRef = React.useRef<HTMLButtonElement | null>(null);
  const popoverRef = React.useRef<HTMLDivElement | null>(null);
  const openPopover = () => {
    if (btnRef && btnRef.current && popoverRef?.current) {
      createPopper(btnRef?.current, popoverRef.current, {
        placement: "bottom",
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              padding: 60,
            },
          },
        ],
      });
      setPopoverShow(true);
    }
  };
  const closePopover = () => {
    setPopoverShow(false);
  };

  const handleChangeProfile = (): void => {
    setOpenModal(true);
  }
  const handleChangeDeadline = (): void => {
    setOpenDeadlineModal(true);
  };
  const handleUserProfileModal = ():void =>{
    setOpenUserProfileModal(true);
    setPopoverShow(false);
  }
  const handleClose = (): void => {
    setOpenModal(false);
    setOpenDeadlineModal(false);
    setOpenUserProfileModal(false);
  };

  function handleDocumentClick(event: MouseEvent) {
    if (popoverRef && event) {
      const { current } = popoverRef;
      if (current && current.contains(event?.target as HTMLDivElement)) {
        return;
      }
    }
    setPopoverShow(false);
  }

  React.useEffect(() => {
    // listen for clicks and close dropdown on body
    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleLogout = (event: React.SyntheticEvent) => {
    event.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem('currentUser');
    localStorage.removeItem('consultantEmail');
    localStorage.removeItem("metaAnswered");
    localStorage.removeItem("metaFeedback");
    localStorage.removeItem("isConsultant");
    localStorage.removeItem("pageNumber");
    localStorage.removeItem("roleSelected");
    sendAmplitudeData(AMPLITUDE_EVENTS.Logout, '');
    if (localStorage.getItem("isConsultant")) {
      history.push(APP_ROUTES.CONSULTANT_LOGIN);
    }
    if (userProfile?.source === Source.Direct ){
      history.push(APP_ROUTES.LOGIN_DIRECT);
    };
    
    window.location.reload();
  };
  return (
    <>
      <div className="flex flex-row">
        <div className="w-full text-center flex items-center">
          {/* {showButton && userProfile?.isConsultant && (
            <button
              type="button"
              className={`focus:outline-none px-2`}
              onClick={handleChangeProfile}
            >
              <div className="items-center flex">
                <div className="w-8 h-8 bg-brand-torquise text-white inline-flex items-center justify-center rounded-full">
                  <img
                    className="w-5 h-5"
                    src="/images/change-status.svg"
                    alt="change status"
                  />
                </div>
                <span className="hidden md:inline text-brand-darkblue text-base px-2">
                  {"Status"}
                </span>
              </div>
            </button>
          )} */}
          {showButton && userProfile?.isConsultant && (
            <button
              type="button"
              className={`focus:outline-none px-2`}
              onClick={handleChangeDeadline}
            >
              <div className="items-center flex">
                <div className="w-8 h-8 bg-brand-torquise text-white inline-flex items-center justify-center rounded-full">
                  <img
                    className="w-5 h-5"
                    src="/images/change-status.svg"
                    alt="change deadline"
                  />
                </div>
                <span className="hidden md:inline text-brand-darkblue text-base px-2">
                  {"Deadline"}
                </span>
              </div>
            </button>
          )}

          <button
            type="button"
            className={`focus:outline-none`}
            onClick={() => {
              popoverShow ? closePopover() : openPopover();
            }}
            ref={btnRef}
          >
            <div className="items-center flex px-2">
              <div className="w-8 h-8 bg-brand-torquise text-white inline-flex items-center justify-center rounded-full">
                <img alt="user" className="m-2" src={"/images/user.svg"} />
              </div>
              <span className="hidden md:inline text-brand-darkblue text-base px-2">
                {showButton ? userProfile.fullName : user?.name}
              </span>
            </div>
          </button>
          <div
            className={`${
              popoverShow ? "" : "hidden "
            } mt-10 w-40 bg-white border block z-50 font-normal leading-normal text-sm shadow no-underline break-words rounded`}
            ref={popoverRef}
          >
            <button
              className={`w-full px-4 py-2 text-base font-medium cursor-pointer text-gray-1`}
              onClick={handleUserProfileModal}
            >
              <div className={`flex items-center`}>
                <div className="px-2 text-sm font-normal capitalize text-brand-darkblue">
                  {"Profile"}
                </div>
              </div>
            </button>

            <button
              className={`w-full px-4 py-2 text-base font-medium cursor-pointer text-gray-1`}
              onClick={(e) => handleLogout(e)}
            >
              <div className={`flex items-center`}>
                <div className="px-2 text-sm font-normal capitalize text-brand-darkblue">
                  Logout
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      {openModal ? <ChangeStatusModal handleClose={handleClose} /> : <></>}
      {openDeadlineModal ? (
        <ChangeDeadlineModal handleClose={handleClose} />
      ) : (
        <></>
      )}
      {openUserProfileModal ? (
        <ApplicantProfileModal handleClose={handleClose} />
      ) : (
        <></>
      )}
    </>
  );
};

export default UserDropdown;
