import React from 'react';

interface ILoaderProps{
  customLoadingText?: string;
}

const Loader: React.FC<ILoaderProps> = (props:ILoaderProps) => {
  return (
    <>
      <div className={`z-50 w-full h-full fixed inset-0 overflow-hidden flex flex-col items-center justify-center`}>
        <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-6 h-10 w-10 mb-2 z-50"></div>
        <h2 className="text-center text-brand-default text-base">{props.customLoadingText ? props.customLoadingText : 'Loading...'}</h2>
      </div>
      <div className={` ${props.customLoadingText ? 'opacity-90': 'opacity-60'} fixed inset-0 z-40 bg-white`}></div>
    </>
  );
};

export default Loader;
