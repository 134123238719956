import * as dashboardConstants from "../constants";
import { dashboardService } from "../services/dashboaardService";
import { alertActions } from "./alertAction";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { IApiError } from "../store/initialState";
import { AnyAction } from "redux";
import {
  IGetDashboardFailure,
  IGetDashboardSuccess,
  IGetDashboardRequest,
  IGetUserSuccess,
  IGetUserRequest,
  IGetUserFailure,
  IGetPublicDashboardSuccess,
} from "./actionTypes";
import { history } from "../helpers/history";
import { APP_ROUTES } from "../utils/routeConstant";
import { ICategory } from "../utils/defaultData";

export const getDashboardRequest = (): IGetDashboardRequest => {
  return { type: dashboardConstants.GET_DASHBOARD_REQUEST };
};

export const getDashboardSuccess = (
  dashboard: ICategory[],
  sample: any,
  selectedSchools: any,
  schoolsEssay: any,
  resumeDoc: any,
  driveFolderLink: any,
  consultants: any,
  profileData: any,
): IGetDashboardSuccess => {
  return { type: dashboardConstants.GET_DASHBOARD_SUCCESS, dashboard , sample, selectedSchools, schoolsEssay, resumeDoc , driveFolderLink , consultants:consultants, profileData: profileData};
};

export const getPublicSectionsSuccess = (
  dashboard: ICategory[],
  sample: any,
): IGetPublicDashboardSuccess => {
  return { type: dashboardConstants.GET_PUBLIC_DASHBOARD_SUCCESS, dashboard , sample};
};

export const getDashboardFailure = (error: IApiError): IGetDashboardFailure => {
  return { type: dashboardConstants.GET_DASHBOARD_FAILURE, error };
};

export const getUserProfiledRequest = (): IGetUserRequest => {
  return { type: dashboardConstants.GET_USER_PROFILE_REQUEST};
};

export const getUserProfileSuccess = (
  response: any
): IGetUserSuccess => {
  return { type: dashboardConstants.GET_USER_PROFILE_SUCCESS, response };
};

export const getUserProfileFailure = (error: IApiError): IGetUserFailure => {
  return { type: dashboardConstants.GET_USER_PROFILE_FAILURE, error };
};

export const categories = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    dispatch(getDashboardRequest());
    await dashboardService
      .dashboard()
      .then((dashboard: any) => {
        dispatch(getUserProfileSuccess({...dashboard?.data.user, profileDataId: dashboard?.data.profileDataId, applicantData: dashboard?.data.applicantData, profileHubspotData: dashboard?.data.userHubspotData}))
        dispatch(getDashboardSuccess(dashboard?.data?.questions, dashboard?.data.sampleData, dashboard?.data.selectedSchools, dashboard?.data.schoolsEssay, dashboard?.data.resumeDoc , dashboard?.data.driveFolderLink , dashboard?.data.consultants, dashboard?.data.profileData));
      })
      .catch((dashboard) => {
        dispatch(getDashboardFailure(dashboard));
        const redirectionUrl = localStorage.getItem("isConsultant")
          ? APP_ROUTES.CONSULTANT_LOGIN
          : APP_ROUTES.LOGIN_DIRECT;
        localStorage.removeItem("token");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("isConsultant");
        localStorage.removeItem("metaAnswered");
        localStorage.removeItem("metaFeedback");
        localStorage.removeItem("pageNumber");
        dispatch(alertActions.error(dashboard));
        history.push(redirectionUrl);
      });
  };
};

export const sections = (): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    await dashboardService
      .publicSections()
      .then((dashboard: any) => {
        console.log("###")
        console.log(dashboard)
        dispatch(getPublicSectionsSuccess(dashboard?.data?.questions, dashboard?.data.sampleData));
      })
      .catch((dashboard) => {
        const redirectionUrl = localStorage.getItem("isConsultant")
          ? APP_ROUTES.CONSULTANT_LOGIN
          : APP_ROUTES.LOGIN;
        localStorage.removeItem("token");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("isConsultant")
        localStorage.removeItem("pageNumber");
        history.push(redirectionUrl);
      });
  };
};


export const dashboardAction = {
  categories,
  getDashboardSuccess,
  getDashboardFailure,
  sections
};
