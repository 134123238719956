import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { InitialStore } from "../store/initialState";
import SchoolsTab from "./SchoolsTab";
import Loader from "./Loader";
import { useLocation, useHistory } from "react-router-dom";
import { APP_ROUTES } from "../utils/routeConstant";
import { schools } from "../constants/schools";
import { sendAmplitudeData } from "../utils/amplitideUtils";
import { AMPLITUDE_EVENTS } from "../utils/amplitudeConstants";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const unique = (value:any, index:any, self:any) => {
  return self.indexOf(value) === index
}

const PublicEssaysPage = () => {
  const history = useHistory();
  let query = useQuery();
  const school = query.get("s");
  const localSelectedSchoolS = localStorage.getItem('selectedSchools') || '';

  useEffect(() => {
    const body = document.getElementsByTagName("body");
    body[0].classList.remove("overflow-hidden");
    sendAmplitudeData(AMPLITUDE_EVENTS.PublicSchoolStructureEssaysOpened, {school:school, query:school})
    sendAmplitudeData(AMPLITUDE_EVENTS.SchoolStructurePageRouteVisit, {publicType: true})
  }, []);

  const [selectedSchools, setSelectedSchools] = useState(
    school ? [{ school: schools[school].id }] : []
  );
  useEffect(() => {
    let filtered: any = [];
    const localSchools = localSelectedSchoolS ? JSON.parse(localSelectedSchoolS) : [];
    
    // Push elements if in query
    if(school)
      localSchools.push(school);
    
    const studentSelectedSchools =  localSchools.filter(unique);

    const filteredEssayPresentSchool = studentSelectedSchools
      ? studentSelectedSchools.filter((id: any) => {
          if (schools[id].essayPresent === true) {
            return id;
          }
        })
      : [];

    for(let elem of filteredEssayPresentSchool) {
      filtered = [{school:elem}, ...filtered]
    }

    if(filtered.length == 0) {
      history.push(APP_ROUTES.ONBOARDING_SECTIONS);
    }

    const newselectedSchools = filtered;
    setSelectedSchools(newselectedSchools);
  }, []);

  return (
    <>
      <div className="tabs tabs-boxed">
        {selectedSchools.length > 0 && (
            <SchoolsTab selectedSchools={selectedSchools} publicType={true} />
          )}
      </div>
    </>
  );
};

export default PublicEssaysPage;
