import { ICategory } from "../utils/defaultData";
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IUserDetails {
  email?: string;
  contact?: string;
}

export interface IAlert {
  message?: string;
  type?: string;
}

export interface IApiError {
  message?: string;
  emailVerified?: boolean;
}
export interface IUser {
  email?: string;
  name?: string;
  id?: string;
  profileId?: number;
  loggingIn?: boolean;
  registering?: boolean;
  loggedIn?: boolean;
  signinError?: string;
  loginError?: string;
  forgetPasswordError?: string;
  forgetPasswordSuccess?: string;
  resetPasswordError?: string;
  emailVerified?:boolean;
  profileDataId?: string;
  userProfileId?: string;
  origin?: string;
  originString?: string;
  source?: string;
  selectedSchools?: any;
  consultants?:any
}

export enum Source {
  Internal= 'internal',
  Direct ='normal',
  OldDirect = 'oldDirect',
}

export interface IUserProfile {
  fullName: string;
  email: string;
  profileDataId?: number;
  id?: number;
  isConsultant?:boolean;
  applicantData?: any;
  profileHubspotData?:any;
  source?: Source.Direct | Source.Internal;
}

export interface ICategoriesResponse {
  loading?: boolean;
  status?: string;
  categories?: ICategory[];
  sample?: any;
  selectedSchools?: any;
  schoolsEssay?: any;
  resumeDoc?: any;
  driveFolderLink?: any;
  consultants?: any;
  profileData?: any;
}

export enum CategoryStatus {
  INITIALIZING = "",
  SUBMITTED = "Submitted",
  SUBMITING = "Submitting",
  SUBMITFAILED = "SubmitFailed",
}

export interface ICommentRequest {
  category: string;
  subCategory?: string;
  profileDataId: number;
  comment: string;
}
export interface IComment {
  id: number;
  profileDataId: number;
  tags: string;
  comment: string;
  createdAt: number;
  name: string;
}
export interface InitialStore {
  alert: IAlert;
  loading: boolean;
  user?: IUser;
  dashboard?: ICategoriesResponse;
  userProfile: IUserProfile;
  answers: IAnswer[] | [];
  submitStatus:
    | CategoryStatus.INITIALIZING
    | CategoryStatus.SUBMITFAILED
    | CategoryStatus.SUBMITING
    | CategoryStatus.SUBMITTED;
  comments: IComment[] | [];
}

export interface IAnswer {
  profileDataId: number;
  questionId: number;
  answer: string;
}

export interface IStatusUpdateRequest {
  userProfileDataId: number;
  category: string;
  status: string;
}

export interface IActiveFlagUpdateRequest {
  active: boolean;
  uid: string;
}

export interface IStatusUpdateConsultantRequest {
  profileDataId: number;
  category: string;
  status: string;
}

export interface IDeadlineUpdateConsultantRequest {
  profileDataId: number;
  category: string;
  deadline: string;
}

export const initialState: InitialStore = {
  alert: {},
  loading: false,
  user: {},
  dashboard: undefined,
  userProfile: {
    fullName: "Full Name",
    email: "",
  },
  answers: [],
  submitStatus: CategoryStatus.INITIALIZING,
  comments: [],
};
