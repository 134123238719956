export const openInNewTab = (url: any) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const findConsultantMeetingLink = (consultants: any) => {
  let meetingLink = "";
  if (consultants && consultants.length > 0) {
    for (let i = 0; i < consultants.length; i++) {
      if (consultants[i].meetingLink) {
        meetingLink = consultants[i].meetingLink;
      }
    }
  }
  return meetingLink;
};