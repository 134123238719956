import React, { useEffect, lazy } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../utils/routeConstant";
import ScrollToTop from "./ScrollToTop";
import ReactGA from "react-ga";
import { InitialStore } from "../store/initialState";
import { useSelector } from "react-redux";
import OnboardingContainer from "../containers/OnboardingContainer";
const UsersTable = lazy(() => import("./UsersTable"));
const PreLoginContainer = lazy(() => import("../containers/PreLoginContainer"));
const MainContainer = lazy(() => import("../containers/MainContainer"));
const ConsultantLoginContainer = lazy(
  () => import("../containers/ConsultantContainer")
);
const FeedbackPage = lazy(() => import("../pages/FeedbackPage"));
const MirrorRedirect = lazy(() => import("../pages/MirrorRedirect"));
const MeetingPriorityOrder = lazy(() => import("../pages/MeetingPriorityOrder"));
const ThankYouPage = lazy(() => import("../pages/ThankYouPage"));
const ConsultantContentPage = lazy(() => import("../components/ConsultantContent"));
const SampleContainer = lazy(() => import("../containers/SampleContainer"));
const NotFound = lazy(() => import("../pages/NotFound"));
const SchoolPage = lazy(() => import("../components/SchoolPage"));

declare const window: any; /* eslint-disable-line  @typescript-eslint/no-explicit-any */

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SwitchOptions: React.FC = (): JSX.Element => {
  const location = useLocation();
  const checkToken = (): boolean => !!localStorage.getItem("token");
  const isTokenAvailable = checkToken();
  const checkConsultant = (): boolean => !!localStorage.getItem("isConsultant");
  const isConsultant = checkConsultant();
  const userProfile = useSelector((state: InitialStore) => state.userProfile);
  let redirectLoginPath = APP_ROUTES.LOGIN;
  if (localStorage.getItem("isConsultant")) {
    redirectLoginPath = APP_ROUTES.CONSULTANT_LOGIN;
  }

  // Setting localstorage items
  let query = useQuery();
  let sourceVal = '';
  let tempSource = '';
  let sourceString = useLocation().search;

  var utm_source = query.get('utm_source')? query.get('utm_source'): 'unknown';
  var utm_campaign = query.get('utm_campaign')? query.get('utm_campaign'): '';
  var utm_medium = query.get('utm_medium') ? query.get('utm_medium'): '';

  if(utm_source || utm_campaign || utm_medium) {
    tempSource = utm_source + '|' + utm_medium + '|'  + utm_campaign;                     
  }

  if(query.get('source')) {
    sourceVal = query.get('source') || "";
  }
  else {
    sourceVal = tempSource;
  }

  if(location.pathname.includes(APP_ROUTES.SIGNUP_DIRECT) || location.pathname.includes(APP_ROUTES.ONBOARDING)) {
    if(sourceVal && sourceString) {
      let localItems = {
        source: sourceVal,
        sourceString: sourceString
      }
      localStorage.setItem('origins', JSON.stringify(localItems));
    }
  }

  useEffect(() => {
      if (process.env && process.env.REACT_APP_ENV === "production") {
        //ReactGA.initialize('G-0743C6CBFH"');
        ReactGA.initialize('UA-128675953-2');
        ReactGA.pageview(window.location.pathname + window.location.search);

        // Live Chat Tawk.to
        try {

          // Only for loggedin cases
          let Tawk_API: any;
          let Tawk_LoadStart: any;
          Tawk_API = Tawk_API || {};

          (function () {
            var s1 = document.createElement("script"),
              s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = "https://embed.tawk.to/61740bb6f7c0440a591fa498/1fimkrgib";
            s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*");
            s0.parentNode?.insertBefore(s1, s0);
          })();

          // Hiding Widget on Login or without token pages
          window.Tawk_API = window.Tawk_API || {};
          window.Tawk_API.onLoad = function(){
            // if(!isTokenAvailable) {
            //   if (window.Tawk_API) {
            //     window.Tawk_API.hideWidget();
            //   }
            // }
          };
          
        }
        catch (error) {

        }
      }

  }, [])
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route
          exact={true}
          path={APP_ROUTES.MAIN}
          children={() => <Redirect to={APP_ROUTES.LOGIN_DIRECT} />}
        />
        <Route
          path={APP_ROUTES.USERTYPE}
          exact={true}
          children={<PreLoginContainer task={"usertype"} />}
        />
        <Route
          path={APP_ROUTES.LOGIN}
          exact={true}
          children={<PreLoginContainer task={"login"} />}
        />
        <Route
          path={APP_ROUTES.LOGIN_DIRECT}
          exact={true}
          children={<PreLoginContainer task={"directLogin"} />}
        />
        <Route
          path={APP_ROUTES.SIGNIN_DIRECT}
          exact={true}
          children={() => {
            if (!isTokenAvailable) {
              return (
                <Redirect
                  to={{
                    pathname: APP_ROUTES.LOGIN_DIRECT,
                    state: { from: location },
                  }}
                />
              );
            } else {
              return (
                <Redirect
                  to={{
                    pathname: APP_ROUTES.DASHBOARD,
                    state: { from: location },
                  }}
                />
              );
            }
          }}
        />
        <Route
          path={APP_ROUTES.FACEBOOK}
          exact={true}
          children={<PreLoginContainer task={"facebook"} />}
        />
        <Route
          path={APP_ROUTES.SIGNUP}
          exact={true}
          children={<PreLoginContainer task={"register"} />}
        />
        <Route
          path={APP_ROUTES.CONSULTANT_SIGNUP}
          exact={true}
          children={<ConsultantLoginContainer task={"register"} />}
        />
        <Route
          path={APP_ROUTES.SIGNUP_DIRECT}
          exact={true}
          children={<PreLoginContainer task={"directRegister"} />}
        />
        <Route
          path={APP_ROUTES.CONSULTANT_LOGIN}
          exact={true}
          children={<ConsultantLoginContainer task={"login"} />}
        />
        <Route
          path={APP_ROUTES.FORGET_PASSWORD}
          exact={true}
          children={<PreLoginContainer task={"forgetpassword"} />}
        />
        <Route
          path={APP_ROUTES.RESET_PASSWORD}
          exact={true}
          children={<PreLoginContainer task={"resetpassword"} />}
        />
        <Route
          exact={true}
          path={APP_ROUTES.MIRROR_REDIRECT}
          children={() => {
            return <MirrorRedirect />;
          }}
        />
        <Route
          exact={true}
          path={APP_ROUTES.MEETING_PRIORITY_ORDER}
          children={() => {
            return <MeetingPriorityOrder />;
          }}
        />
        <Route
          exact={true}
          path={APP_ROUTES.FEEDBACK}
          children={() => {
            if (!isTokenAvailable) {
              return (
                <Redirect
                  to={{
                    pathname: APP_ROUTES.LOGIN_DIRECT,
                    state: { from: location },
                  }}
                />
              );
            } else {
              return <FeedbackPage />;
            }
          }}
        />

        <Route
          exact={true}
          path={APP_ROUTES.THANKYOU}
          children={() => {
            if (!isTokenAvailable) {
              return (
                <Redirect
                  to={{
                    pathname: APP_ROUTES.LOGIN_DIRECT,
                    state: { from: location },
                  }}
                />
              );
            } else {
              return <ThankYouPage />;
            }
          }}
        />

        <Route
          path={APP_ROUTES.ALL_USERS}
          exact={true}
          children={() => {
            if (!isTokenAvailable) {
              return (
                <Redirect
                  to={{
                    pathname: redirectLoginPath,
                    state: { from: location },
                  }}
                />
              );
            } else {
              return <UsersTable />;
            }
          }}
        />
        <Route
          path={APP_ROUTES.CONSULTANT_SCHOOL_ESSAYS}
          exact={true}
          children={() => {
            if (!isTokenAvailable) {
              return (
                <Redirect
                  to={{
                    pathname: redirectLoginPath,
                    state: { from: location },
                  }}
                />
              );
            } else {
              return <ConsultantContentPage phase="phase7" />;
            }
          }}
        />
        <Route
          path={APP_ROUTES.CONSULTANT_RESUME_RESOURCES}
          exact={true}
          children={() => {
            if (!isTokenAvailable) {
              return (
                <Redirect
                  to={{
                    pathname: redirectLoginPath,
                    state: { from: location },
                  }}
                />
              );
            } else {
              return <ConsultantContentPage phase="phase8" />;
            }
          }}
        />
        <Route
          path={APP_ROUTES.CONSULTANT_INTERVIEW_RESOURCES}
          exact={true}
          children={() => {
            if (!isTokenAvailable) {
              return (
                <Redirect
                  to={{
                    pathname: redirectLoginPath,
                    state: { from: location },
                  }}
                />
              );
            } else {
              return <ConsultantContentPage phase="phase9" />;
            }
          }}
        />
        <Route
          path={APP_ROUTES.DASHBOARD}
          children={() => {
            if (!isTokenAvailable) {
              return (
                <Redirect
                  to={{ pathname: APP_ROUTES.LOGIN_DIRECT, state: { from: location } }}
                />
              );
            } else {
              return <MainContainer />;
            }
          }}
        />
        <Route
          path={APP_ROUTES.SAMPLE}
          children={() => {
            if (!isTokenAvailable) {
              return (
                <Redirect
                  to={{ pathname: APP_ROUTES.LOGIN, state: { from: location } }}
                />
              );
            } else {
              return <SampleContainer />;
            }
          }}
        />
        <Route
          path={APP_ROUTES.ONBOARDING}
          children={() => {
            if (isTokenAvailable) {
              return <Redirect to={{ pathname: APP_ROUTES.DASHBOARD }} />;
            } else {
              return <OnboardingContainer task={"schools"} />;
            }
          }}
        />
        <Route
          path={APP_ROUTES.ONBOARDING_ESSAYS}
          children={() => {
            if (isTokenAvailable) {
              return <Redirect to={{ pathname: APP_ROUTES.DASHBOARD }} />;
            } else {
              return <OnboardingContainer task={"essays"} />;
            }
          }}
        />
        <Route
          path={APP_ROUTES.ONBOARDING_SECTIONS}
          children={() => {
            if (isTokenAvailable) {
              return <Redirect to={{ pathname: APP_ROUTES.DASHBOARD }} />;
            } else {
              return <OnboardingContainer task={"sections"} />;
            }
          }}
        />
         <Route
          path={APP_ROUTES.LATEST_SAMPLE}
          children={() => {
            if (!isTokenAvailable) {
              return (
                <Redirect
                  to={{ pathname: APP_ROUTES.LOGIN, state: { from: location } }}
                />
              );
            } else {
              return <SampleContainer />;
            }
          }}
        />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default SwitchOptions;
