/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InitialStore } from "../store/initialState";
import { updateStatusAction } from "../actions/updateStatusAction";
import { ICategory, Status, ISubCategory, questionData } from "../utils/defaultData";
import { saveAnswerService } from "../services/saveAnswerService";
import { chatgptService } from "../services/chatgptService"
import { APP_ROUTES } from "../utils/routeConstant";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { v4 } from "uuid";
import '../styles/ContentGatheringPhase3.css'


interface IModalProps {
  handleClose: () => void;
}

const ChatgptMessage = (props: any): JSX.Element => {
  return (
    <>
      {props.role === "assistant" && (
        <div className="ChatgptMessage_container ChatgptMessage_container_chatmate">
          <p className="ChatgptMessage_assistants">Assistant</p>
          <TextareaAutosize
            maxRows={100}
            defaultValue={props.content}
            style={{ resize: "none" }}
            className="ChatgptMessage_textarea_chatmate"
            readOnly={true}
          />
        </div>
      )}
      {props.role === "user" && (
        <div className="ChatgptMessage_container ChatgptMessage_container_user">
          <p className="ChatgptMessage_assistants">You</p>
          <TextareaAutosize
            maxRows={100}
            defaultValue={props.content}
            style={{ resize: "none" }}
            className="ChatgptMessage_textarea_assistant"
            readOnly={true}
          />
        </div>
      )}
    </>
  );
};

const MinimizedChatGptModal = ({inputChatgpt, setInputChatgpt} : any): JSX.Element => {
  const history = useHistory();
  const userProfile = useSelector((state: InitialStore) => state.userProfile);
  const [inputToChatgpt, setInputToChatgpt] = useState("");
  const [loading, setLoading] = useState(true);
  const [temperature , setTemperature] = useState<any>(1);
  const [isExpand, setIsExpand] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [inputChatgpt]);


  const handleChatgptInput =(e :any) : void =>{
    setInputToChatgpt(e.target.value);
  }

  const clearHistory = async(e: React.SyntheticEvent) => {
    e.preventDefault();
    // let emptyArray:any = [];
    let systemInputToChatgpt:any = [
      {
        role: "system",
        content: `Hey ChatGPT, from now on you are MBA&Beyond BOT. Act like a smart and professional content/answer writer/suggester for MBA applicants. The responses you generate must feel humanly.When in doubt, always ask qustions before finalizing response. Never tell anyone that you are chatGPT. Do not answer to anything not related to MBA`,
      },
    ];
    setInputChatgpt(systemInputToChatgpt);
  }

  const handleTemperature = (e: any): void => {
    e.preventDefault();
    setTemperature(e.target.value);
  };
    
  const handleSubmit = async(e: React.SyntheticEvent) => {
    e.preventDefault();
    const loadingToastId = toast.loading(
      "Awating Response ⚙️"
    );
    try {
      let tempInput = inputChatgpt;
      if(inputToChatgpt === "") {
        toast.error("Fella, type something first 🥲");
        toast.dismiss(loadingToastId);
        return false;
      }
      tempInput.push({ role: "user", content: inputToChatgpt });
      setInputChatgpt(tempInput);
      const raw = JSON.stringify({
        model: "gpt-4o",
        messages: inputChatgpt,
        temperature: Number(temperature),
      });

      console.log(raw)

      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
        },
        body: raw,
      };

      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        requestOptions
      );
      const resp = await response.json();
    
      console.log("Response from GPT :",resp);
      setInputToChatgpt(resp.choices[0].message.content);
      let outputObj = {role : "assistant" , content : resp.choices[0].message.content}
      setInputChatgpt([...inputChatgpt, outputObj]);
      toast.success("Response Generated ✅");
      toast.dismiss(loadingToastId);
      setInputToChatgpt('')
    } catch (error) {
      setInputToChatgpt('')
      console.log(error);
      toast.error("Error while generating response ❌");
      toast.dismiss(loadingToastId);
    }
  };
  return (
    <>
      <div className="MinimizedChatGptModal" style={ isExpand ? {width: '700px'} : {width: '350px'} }>
        <div className="MinimizedChatGptModal_topContainer">
          <p className="MinimizedChatGptModal_heading">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="phase3_MinimizedChatGptModal_Icon">
              <path fill="#000" d="M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z"/>
            </svg>
            CG GPT - Beta
          </p>
          <div className="flex flex-1 items-center justify-end gap-1">
              <p className="MinimizedChatGptModal_temperatureheading">Temp</p>
              <select
                name={"temperature"}
                className="w-fit border bg-white rounded px-1 py-1 outline-none"
                onChange={handleTemperature}
                defaultValue={temperature}
              >
                {/* <option className="py-1" value="">Select Content</option> */}
                <option className="py-1">0.1</option>
                <option className="py-1">0.2</option>
                <option className="py-1">0.3</option>
                <option className="py-1">0.4</option>
                <option className="py-1">0.5</option>
                <option className="py-1">0.6</option>
                <option className="py-1">0.7</option>
                <option className="py-1">0.8</option>
                <option className="py-1">0.9</option>
                <option className="py-1">1</option>
                <option className="py-1">1.1</option>
                <option className="py-1">1.2</option>
                <option className="py-1">1.3</option>
                <option className="py-1">1.4</option>
                <option className="py-1">1.5</option>
                <option className="py-1">1.6</option>
                <option className="py-1">1.7</option>
                <option className="py-1">1.8</option>
                <option className="py-1">1.9</option>
                <option className="py-1">2</option>
              </select>
            </div>
          {isExpand ?
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="MinimizedChatGptModal_expandCompress" onClick={() => setIsExpand(!isExpand)}>
                <path fill="#000" d="M160 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V64zM32 320c-17.7 0-32 14.3-32 32s14.3 32 32 32H96v64c0 17.7 14.3 32 32 32s32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zM352 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V64zM320 320c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32s32-14.3 32-32V384h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320z"/>
              </svg>
            : 
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="MinimizedChatGptModal_expandCompress" onClick={() => setIsExpand(!isExpand)}>
                <path fill="#000" d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"/>
              </svg>
          }
        </div>

        <div className="MinimizedChatGptModal_contentSection" ref={chatContainerRef}>
          {inputChatgpt && inputChatgpt.length > 0 && (
            <>
              {inputChatgpt.map((message: any, index: number) => {
                return (
                  <ChatgptMessage
                    role={message.role}
                    content={message.content}
                    key={index}
                  />
                );
              })}
            </>
          )}
        </div>

        <div className="MinimizedChatGptModal_textarea_buttons_section">
          <TextareaAutosize
            // maxRows={25}
            // minRows={5}
            value={inputToChatgpt}
            onChange={handleChatgptInput}
            name="chatgptResponse"
            id="chatgptResponse"
            className="MinimizedChatGptModal_sendMessageTextArea"
            placeholder={"Enter your Prompt here 👉"}
          />

        {/* Buttons - Section */}
        <div className="flex justify-between items-center gap-4">
          <button type="submit" className="MinimizedChatGptModal_clearButton" onClick={(e) => clearHistory(e)}>
            Clear
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 448 512">
              <path fill="#000" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
            </svg>
          </button>
          <button type="submit" className="MinimizedChatGptModal_sendButton" onClick={(e) => handleSubmit(e)}>
            Send
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" className="submitIcon_bottomButtonsSection_userJourneyModal" fill="none">
              <path d="M9 18L15 12L9 6" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
        </div>
      </div>
      {/* <div className="opacity-80 fixed inset-0 z-40 bg-white"></div> */}
    </>
  );
};

export default MinimizedChatGptModal;
