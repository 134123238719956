interface IModalProps {
  handleClose: () => void;
}

const LockedSectionModal = (props: IModalProps): JSX.Element => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden px-6 h-full overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="w-96 relative overflow-hidden transition-all md:h-auto transform bg-white md:rounded-2xl sm:shadow-xl md:max-w-xl flex flex-col max-h-screen-resp  bg-brand-darkblue"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-brand-darkblue absolute top-0 z-10 bg-white w-full px-4 py-3 flex justify-between border-b border-brand-darkblue text-white">
            <div className="text-white font-bold text-xl"></div>
            <button
              className="text-gray-3 rounded-full focus:outline-none"
              onClick={() => props.handleClose()}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#ffffff"
              >
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
              </svg>
            </button>
          </div>
          <div className="text-center text-white font-bold text-xl my-4 mt-12 px-8 text-sm text-white">
            This section is locked
          </div>
          <p className="text-center text-white mt-2 mb-10 px-4 text-sm">Please contact your application buddy for any queries.</p>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-white"></div>
    </>
  );
};

export default LockedSectionModal;
