import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { InitialStore, Source } from "../store/initialState";
import { sendAmplitudeData } from "../utils/amplitideUtils";
import { AMPLITUDE_EVENTS } from "../utils/amplitudeConstants";
import { APP_ROUTES } from "../utils/routeConstant";
import UserDropdown from "./UserDropdown";

interface NavbarProps {
  handleDriveOpen: () => void;
  handleFaqOpen: () => void;
  handleSchoolSelectionOpen?: () => void;
  hideSchoolSelection?: boolean;
  bgColor?: string;
  publicType?: boolean;
}

const Navbar = (props: NavbarProps): JSX.Element => {
  const userProfile = useSelector((state: InitialStore) => state?.userProfile);
  const dashboard = useSelector((state: InitialStore) => state?.dashboard);
  return (
    <>
      {/* Navbar */}
      <nav
        className={`absolute md:border-b top-0 left-0 w-full z-10 ${
          props.bgColor ? props.bgColor : "bg-transparent"
        } md:flex-row md:flex-nowrap md:justify-start flex items-center p-4`}
      >
        {userProfile.isConsultant && userProfile?.applicantData && (
          <div
            className="lg:px-4 text-sm text-brand-darkblue hidden md:block md:w-30 lg:w-full "
            title={userProfile.applicantData.email}
          >
            Applicant:{" "}
            <span className="font-medium text-brand-darkblue">
              {userProfile.applicantData.fullName}{" "}
            </span>
            <span className="text-brand-grey text-xs">
              {" "}
              ({userProfile.applicantData.email})
            </span>
          </div>
        )}
        {userProfile.isConsultant && userProfile?.applicantData && dashboard?.consultants && dashboard.consultants.length > 0 && (
          <div
            className="lg:px-4 text-sm text-brand-darkblue hidden md:w-30 lg:w-full "
          >
            Consultant:{" "}
            {dashboard.consultants &&
              dashboard.consultants.map(
                (cons: any, id:number) => (
                  <span>
                    {id+1}. {cons.fullName} ({cons.email}),
                  </span>
                )
              )}
            <span className="font-medium text-brand-darkblue">
              {userProfile.applicantData.fullName}{" "}
            </span>
            <span className="text-brand-grey text-xs">
              {" "}
              ({userProfile.applicantData.email})
            </span>
          </div>
        )}
        <div className="w-full mx-auto md:justify-end items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {false && !userProfile.isConsultant && userProfile.source === Source.Direct ? (
            <div
              className="text-xs hidden md:block text-brand-darkblue px-3 py-2 bg-brand-torquise border border-brand-torquise rounded-3xl mx-4 cursor-pointer"
              onClick={props.handleSchoolSelectionOpen}
            >
              {"My schools"}
            </div>
          ) : (
            <></>
          )}

          {props.publicType && (
            <Link
            onClick={() =>{
              sendAmplitudeData(AMPLITUDE_EVENTS.PublicLTopRegisterClick, {publicType:props.publicType});
            }}
            className={`text-xs hidden md:block text-white px-3 py-2 bg-brand-torquise border border-brand-torquise rounded-3xl mx-4 cursor-pointer`}
            to={APP_ROUTES.SIGNUP_DIRECT}
          >
            <div className="">
              {"Register now "}
            </div>
          </Link>
          )}

          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex space-x-2 p-0">
            <button
              className="focus:outline-none"
              onClick={() => props.handleFaqOpen()}
              title="FAQs"
            >
              <div className="w-8 h-8 bg-brand-torquise text-white inline-flex items-center justify-center rounded-full">
                <img
                  src="/images/questionmark.svg"
                  className="w-5 h-5 text-brand-darkblue"
                />
              </div>
            </button>
            <button
              className="focus:outline-none"
              onClick={() => props.handleDriveOpen()}
              title="Drive"
            >
              <div className="w-8 h-8 bg-brand-torquise text-white inline-flex items-center justify-center rounded-full">
                <img
                  src="/images/drive.png"
                  className="w-5 h-5 text-brand-darkblue"
                />
              </div>
            </button>
            {userProfile && userProfile.id && (
                <UserDropdown
                showButton={true}
                handleSchoolSelectionOpen={props.handleSchoolSelectionOpen}
              />
            )}

          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
};

export default Navbar;
