/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrls } from '../utils/apiUrls';
import axiosInstance from '../helpers/interceptor';
import { IAnswer } from '../store/initialState';

const save = async (answer: IAnswer): Promise<void> => {
  const { data } = await axiosInstance.post(apiUrls.POST_ANSWER, answer);
  return data.response;
};

const saveDirect = async ({answer , email} : {answer: any, email: string}): Promise<void> => {
  const { data } = await axiosInstance.post(apiUrls.POST_DIRECT_ANSWER, {'email' : email , 'questions' : answer});
  return data;
};


const getSavedAnswers = async (profileDataId: number): Promise<void> => {
  const unParsedUid = localStorage.getItem('currentUser') || JSON.stringify(0);
  const uid = JSON.parse(unParsedUid)
  const url = uid ? `${apiUrls.GET_ANSWERS}=${profileDataId}&uid=${uid}`: `${apiUrls.GET_ANSWERS}=${profileDataId}`;
  const { data } = await axiosInstance.get(url);
  return data.response;
};

export const saveAnswerService = {
  save,
  saveDirect,
  getSavedAnswers,
};
