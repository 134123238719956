/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrls } from '../utils/apiUrls';
import { IStatusUpdateRequest, IStatusUpdateConsultantRequest, IDeadlineUpdateConsultantRequest ,IActiveFlagUpdateRequest } from '../store/initialState';
import axiosInstance from '../helpers/interceptor';

const update = async (status: IStatusUpdateRequest): Promise<void> => {
  const { data } = await axiosInstance.post(apiUrls.POST_STATUS, status);
  return data.response;
};

const updateByConsultant = async (status: IStatusUpdateConsultantRequest): Promise<void> => {
  const unParsedUid = localStorage.getItem('currentUser') || JSON.stringify(0);
  const uid = JSON.parse(unParsedUid)
  const { data } = await axiosInstance.put(apiUrls.PUT_CONSULTANT_STATUS, {...status ,uid });
  return data.response;
};

const deadlineUpdateByConsultant = async (deadline: IDeadlineUpdateConsultantRequest): Promise<void> => {
  const unParsedUid = localStorage.getItem('currentUser') || JSON.stringify(0);
  const uid = JSON.parse(unParsedUid)
  const { data } = await axiosInstance.put(apiUrls.PUT_CONSULTANT_DEADLINE, {...deadline ,uid });
  return data.response;
};

const updateUserActiveFlag = async (params: IActiveFlagUpdateRequest): Promise<void> => {
  const { data } = await axiosInstance.put(apiUrls.PUT_CONSULTANT_USER_ACTIVE, params);
  return data.response;
};

export const updateStatusService = {
  update,
  updateByConsultant,
  updateUserActiveFlag,
  deadlineUpdateByConsultant,
};
 