import * as dashboardConstants from '../constants';
import {
IGetDashboardFailure,
IGetDashboardSuccess,
IGetDashboardRequest,
IGetAnswersFailure,
IGetAnswersRequest,
IGetAnswersSuccess,
} from '../actions/actionTypes';
import { initialState } from '../store/initialState';



export const loading = (
  loadingState = initialState.loading,
  action:
    | IGetDashboardFailure
    | IGetDashboardRequest
    | IGetDashboardSuccess
    | IGetAnswersFailure
    | IGetAnswersRequest 
    | IGetAnswersSuccess
): boolean => {
  switch (action.type) {
    case dashboardConstants.GET_DASHBOARD_REQUEST:
      return true
    case dashboardConstants.GET_DASHBOARD_FAILURE:
        return false
    case dashboardConstants.GET_ANSWERS_SUCCESS:
        return false
    case dashboardConstants.GET_ANSWERS_FAILURE:
        return true 
    default:
      return  loadingState;
  }
};
