import * as dashboardConstants from "../constants";
import {
  IGetDashboardFailure,
  IGetDashboardSuccess,
  IGetDashboardRequest,
  IGetPublicDashboardSuccess,
} from "../actions/actionTypes";
import { initialState, ICategoriesResponse } from "../store/initialState";

export const dashboard = (
  dashboardState = initialState.dashboard,
  action: IGetDashboardFailure | IGetDashboardRequest | IGetDashboardSuccess | IGetPublicDashboardSuccess
): ICategoriesResponse => {
  switch (action.type) {
    case dashboardConstants.GET_DASHBOARD_REQUEST:
      return {
        categories: [],
        loading: false,
      };
    case dashboardConstants.GET_DASHBOARD_SUCCESS: 
      return {
        categories: [...action.dashboard],
        loading: true,
        status: "success",
        sample: { ...action.sample },
        selectedSchools: { ...action.selectedSchools },
        schoolsEssay : [...action.schoolsEssay],
        resumeDoc : action.resumeDoc || {},
        driveFolderLink : action.driveFolderLink || '',
        consultants:  action?.consultants || [],
        profileData:  action?.profileData || {},
      };
    case dashboardConstants.GET_PUBLIC_DASHBOARD_SUCCESS: 
      return {
        categories: [...action.dashboard],
        loading: true,
        status: "success",
        sample: { ...action.sample },
      };
    case dashboardConstants.GET_DASHBOARD_FAILURE:
      return {
        ...dashboardState,
        loading: true,
        status: "failure",
        categories: [],
      };
    default:
      return { ...dashboardState };
  }
};
