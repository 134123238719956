import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import QuestionPage from "../pages/QuestionPage";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Drawer from "./Drawer";
import { openInNewTab } from "../utils/helpers";
import { InitialStore, Source } from "../store/initialState";
import SchoolSelectionDrawer from "./SchoolSelectionDrawer";
import { sendAmplitudeData } from "../utils/amplitideUtils";
import { AMPLITUDE_EVENTS } from "../utils/amplitudeConstants";
import Loader from "./Loader";


const DashboardSectionForCategories = (props: any) => {
  console.log({props})
  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const userProfile = useSelector((state: InitialStore) => state.userProfile);
  const dashboard = useSelector((state: InitialStore) => state.dashboard);
  const [isSchoolSelectionDone, setSchoolSelectionDone] = useState(false);
  const handlSchoolSelectionOpen = (): void => {
    setSchoolSelectionDone(true);
    const body = document.getElementsByTagName("body");
    body[0].classList.add("overflow-hidden");
  };
  const handleSchoolSelectionClose = (): void => {
    setSchoolSelectionDone(false);
    const body = document.getElementsByTagName("body");
    body[0].classList.remove("overflow-hidden");
  };
  const handleDriveOpen = (): void => {
    const finalLink = `https://drive.google.com/drive/u/0/folders/${dashboard?.driveFolderLink}`;
    openInNewTab(finalLink);
  }
  const handleFaqOpen = (): void => {
    setIsFaqOpen(true);
    const body = document.getElementsByTagName('body');
    body[0].classList.add('overflow-hidden');
    sendAmplitudeData(AMPLITUDE_EVENTS.FAQOpened, "");
  };
  const handleFaqClose = (): void => {
    setIsFaqOpen(false);
    const body = document.getElementsByTagName('body');
    body[0].classList.remove('overflow-hidden');
    sendAmplitudeData(AMPLITUDE_EVENTS.FAQClosed, "");
  };

  useEffect(() => {
    if (dashboard?.loading && !userProfile.isConsultant) {
      let selectedSchool: any = [];
      if (dashboard.selectedSchools) {
        for (const property in dashboard.selectedSchools) {
          selectedSchool.push(dashboard.selectedSchools[property]);
        }
      }
      const isSchoolsAvailable =
        dashboard?.selectedSchools && selectedSchool.length > 0;
      if (userProfile?.source === Source.Direct && !isSchoolsAvailable) {
        handlSchoolSelectionOpen();
      }
    }
  }, [userProfile, dashboard]);
  if (!userProfile.source && !props.publicType) {
    return <Loader />;
  }
  return (
    <>
      <div className="relative md:ml-64">
      <Sidebar handlSchoolSelectionOpen={handlSchoolSelectionOpen} handleFaqOpen={handleFaqOpen} publicType={props.publicType} />
        <Navbar handleFaqOpen={handleFaqOpen} handleDriveOpen={handleDriveOpen} handleSchoolSelectionOpen={handlSchoolSelectionOpen} publicType={props.publicType} />
        {/* Component */}
        <div className="relative md:pt-24 pb-12 pt-4">
          <QuestionPage
            pageData={props.pageData}
            openModal={props.openModal}
            phase={props.phase}
            showSubmitButton={props.showSubmitButton}
            handleClose={props.handleClose}
            handleOpenModal={props.handleOpenModal}
            publicType={props.publicType}
            userProfileData={userProfile}
          />
        </div>
        <Drawer isOpen={isFaqOpen} handleClose={handleFaqClose} />
        {/* <SchoolSelectionDrawer isOpen={isSchoolSelectionDone} handleClose={handleSchoolSelectionClose} /> */}
        {/* End Component*/}
      </div>
    </>
  );
};

export default DashboardSectionForCategories