import React from "react";

export default function FAQ(props: any) {
  const { question, answer } = props;
  return (
    <div className="px-6 w-full">
      <div className="border shadow rounded-md p-4">
        <div className="font-semibold inline text-brand-darkblue font-base">
          {question}
        </div>
        <div
          className="text-brand-darkblue font-base"
          dangerouslySetInnerHTML={{
            __html: answer.replace(/(?:\r\n|\r|\n)/g, "<br>"),
          }}
        ></div>
      </div>
    </div>
  );
}
