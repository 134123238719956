import * as dashboardConstants from "../constants";
import {
  IGetAnswersFailure,
  IGetAnswersSuccess,
  IGetAnswersRequest,
  IUpdateAnswerSuccess,
} from "../actions/actionTypes";
import { initialState, IAnswer } from "../store/initialState";

export const answers = (
  answerState: IAnswer[] = initialState.answers,
  action:
    | IGetAnswersFailure
    | IGetAnswersRequest
    | IGetAnswersSuccess
    | IUpdateAnswerSuccess
): IAnswer[] => {
  switch (action.type) {
    case dashboardConstants.GET_ANSWERS_REQUEST:
      return [];
    case dashboardConstants.GET_ANSWERS_SUCCESS:
      return [...action.answers];
    case dashboardConstants.GET_ANSWERS_FAILURE:
      return [];
    case dashboardConstants.UPDATE_ANSWER_SUCCESS:
      return [...action.answers];
    default:
      return answerState;
  }
};
