import { apiUrls } from "../utils/apiUrls";
import axiosInstance from "../helpers/interceptor";

const getConsultantDocument = async (profileDataId: number): Promise<void> => {
    const isConsultantSelectedUser = localStorage.getItem("currentUser");
    const { data } = await axiosInstance.get(`${apiUrls.GET_CONSULTANT_DOCUMENT}?uid=${isConsultantSelectedUser}`);
    return data.response;
  };
export const consultantDocumentService = {
    getConsultantDocument
};