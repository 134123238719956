import { combineReducers } from "redux";
import { user } from "./userReducer";
import { alert } from "./alertReducers";
import { dashboard } from "./dashboardReducer";
import { userProfile } from "./userProfileReducer";
import { answers } from "./saveAnswerReducer";
import { submitStatus } from "./updateStatusReducer";
import { comments } from "./commentReducer";
import { loading } from './loadingReducer';

const rootReducer = combineReducers({
  user,
  alert,
  dashboard,
  userProfile,
  answers,
  submitStatus,
  comments,
  loading,
});

export default rootReducer;
