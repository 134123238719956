import React, { lazy, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../utils/routeConstant";
import { useTranslation } from "react-i18next";
import Onboarding from "../components/Onboarding";
import { dashboardAction } from "../actions/dashboardAction";
import { useDispatch } from "react-redux";
import OnboardingBlockerModal from "../components/OnboardingBlockerModal";

interface IOnboardingContainer {
  task: string;
}
const OnboardingContainer: React.FC<IOnboardingContainer> = (
  props: IOnboardingContainer
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const autologinRoutes = [APP_ROUTES.LOGIN, APP_ROUTES.SIGNUP];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && autologinRoutes.includes(location.pathname)) {
      const lastVisited = localStorage.getItem("lastVisited");
      if(lastVisited && lastVisited.includes('phase3')) {
        let redirect = APP_ROUTES.DASHBOARD + `${lastVisited}`;
        history.push(redirect);
      }
      else {
        history.push(APP_ROUTES.DASHBOARD);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(dashboardAction.sections());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const { t } = useTranslation();
  return (
    <>
       <Onboarding task={props.task} />
    </>
  );
};

export default OnboardingContainer;
