export const POST_COMMENTS_REQUEST = 'POST_COMMENTS_REQUEST';
export type POST_COMMENTS_REQUEST = typeof POST_COMMENTS_REQUEST;

export const POST_COMMENTS_SUCCESS = 'POST_COMMENTS_SUCCESS';
export type POST_COMMENTS_SUCCESS = typeof POST_COMMENTS_SUCCESS;

export const POST_COMMENTS_FAILURE = 'POST_COMMENTS_FAILURE';
export type POST_COMMENTS_FAILURE = typeof POST_COMMENTS_FAILURE;

export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export type GET_COMMENTS_REQUEST = typeof GET_COMMENTS_REQUEST;

export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
export type GET_COMMENTS_SUCCESS = typeof GET_COMMENTS_SUCCESS;

export const GET_COMMENTS_FAILURE = 'GET_COMMENTS_FAILURE';
export type GET_COMMENTS_FAILURE = typeof GET_COMMENTS_FAILURE;