import { useState, useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Compprops, IPopup, ISubCategory } from "../utils/defaultData";
import Modal from "./Modal";
import DashboardSection from "./DashboardSection";
import DashboardSectionForCategories from "./DashboardSectionForCategories";
import OnboardingBlockerModal from "./OnboardingBlockerModal";
import { APP_ROUTES } from "../utils/routeConstant";


const DashboardComponent = (props: Compprops) => {
  let { path } = useRouteMatch();
  const firstLinkitem =
    props.category?.nestedCategories && props.category?.nestedCategories[0];
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [popupData, setPopupData] = useState<IPopup | undefined>();
  const handleOpenModal = (
    status: boolean,
    data: IPopup | undefined,
    path: string
  ): void => {
    setOpenModal(status);
    const previousStatus = localStorage.getItem("audioStatus");
    const parsedStatus: string[] | undefined =
      previousStatus && JSON.parse(previousStatus);
    const previousData = parsedStatus && [...parsedStatus, path];
    const uniqueData =
      parsedStatus &&
      previousData?.filter(
        (item, index) => previousData.indexOf(item) === index
      );
    parsedStatus
      ? localStorage.setItem("audioStatus", JSON.stringify(uniqueData))
      : localStorage.setItem("audioStatus", JSON.stringify([path]));
    data && setPopupData({ ...popupData, ...data });
  };
  const handleClose = (): void => {
    setOpenModal(false);
  };


  return (
    <>
      {props.nestedCategories?.length > 0 ? (
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/${firstLinkitem?.key}`} />
          </Route>
          
          {props.nestedCategories?.map((nestedCategory: ISubCategory) => {
            const nestedpath = `${path}/${nestedCategory.key}`;
             
             // Public Type
            return (
              <Route key={`${nestedCategory.heading}`} path={nestedpath}>
                <DashboardSection
                  pageData={nestedCategory}
                  nestedCategories={props.nestedCategories}
                  phase={props.phase}
                  openModal
                  handleClose={handleClose}
                  handleOpenModal={handleOpenModal}
                  subcategory={nestedCategory.key}
                  publicType={props.publicType}
                />
              </Route>
            );
          })}
        </Switch>
      ) : (
        <DashboardSectionForCategories
          pageData={props.category}
          openModal
          phase={props.phase}
          showSubmitButton={(props.phase === 'phase1' || props.phase === 'phase3') ? true: false}
          handleClose={handleClose}
          handleOpenModal={handleOpenModal}
          publicType={props.publicType}
        />
      )}
      {openModal && popupData ? (
        <Modal handleClose={handleClose} popup={popupData} />
      ) : (
        <></>
      )}
    </>
  );
};

export default DashboardComponent;
