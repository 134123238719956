import { useRef, useState, useEffect, useCallback } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { IAnswer, InitialStore } from "../store/initialState";
import { saveAnswerService } from "../services/saveAnswerService";
import _ from "lodash";
import { sendAmplitudeData } from '../utils/amplitideUtils';
import { AMPLITUDE_EVENTS } from '../utils/amplitudeConstants'; 

interface IMCEditor {
  placeholder?: string;
  questionId: any;
  answer: string;
  saving: string;
  handleSave?: (value: string, answer?: IAnswer) => void;
  readonly: boolean;
}
const MCEditor = (props: IMCEditor): JSX.Element => {
  const editorRef = useRef<any>(null);
  const [value, setValue] = useState(props.answer);
  const [answer, setAnswer] = useState("");
  const userProfile = useSelector((state: InitialStore) => state.userProfile);
  const debounced = useCallback(
    _.debounce((newValue, options) => {
      //console.log(newValue);

      // Hack for Direct answers flow
      if(props.questionId === 'question_1') {
        setTimeout(() => props?.handleSave && props.handleSave("", newValue), 100);
      }
      else {
        props.handleSave && props.handleSave("Saving...");
        saveAnswerService
        .save({
          answer: newValue,
          profileDataId: userProfile.profileDataId || 0,
          questionId: props.questionId,
        })
        .then((response: any) => {
          //console.log(response)

          props.handleSave && props.handleSave("Saved", response.data.answer);
          sendAmplitudeData(AMPLITUDE_EVENTS.SaveAnswer, {answer: response.data.answer, type: 'Editor', wordcount: options?.wordCount})
          setTimeout(() => props?.handleSave && props.handleSave(""), 3000);
        })
        .catch(() => {
          props.handleSave && props.handleSave("Error in saving");
          setTimeout(() => props?.handleSave && props.handleSave(""), 3000);
        });
      }
    }, 2000),
    []
  );
const dispatch = useDispatch()
  const onEditorStateChange = (newValue :any, editor:any) => {
    let options = {
      wordCount: editor.plugins.wordcount.getCount()
    };

    console.log(newValue,editor.getContent())
    // Stoping in case of readonly
    if(!props.readonly && userProfile && userProfile?.id) {
      setValue(editor.getContent());
      debounced(newValue, options);
    }
  };
  useEffect(() => {
    
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer]);
  const init =
    props.readonly || userProfile?.isConsultant
      ? {
          readonly: true,
          height: 240,
          branding: false,
          menubar: false,
          plugins: [
            "autoresize",
            "autolink lists link image",
            "preview",
            "searchreplace  code wordcount",
            "insertdatetime media table paste noneditable",
          ],
          placeholder: props?.placeholder || "",
          content_style:
            '@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");  body { font-family:Open Sans,Arial,sans-serif; }',
          toolbar:
            "formatselect | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent | link | table | preview",

          //toolbar1:' formatselect fontsizeselect fontselect tokens | bold italic | alignleft aligncenter alignright | undo redo  ',
          //toolbar2 : 'forecolor backcolor | bullist numlist outdent indent | image |  table | link | code | insertdatetime |  preview | searchreplace | blockquote dialog-example-btn ',
        }
      : {
          height: 240,
          branding: false,
          menubar: false,
          plugins: [
            "autolink lists link image",
            "preview",
            "searchreplace  code",
            "insertdatetime media table paste noneditable",
            "wordcount"
          ],
          placeholder: props?.placeholder || "",
          content_style:
            '@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");  body { font-family:Open Sans,Arial,sans-serif; }',
          toolbar:
            "formatselect | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent | link | table | preview",

          //toolbar1:' formatselect fontsizeselect fontselect tokens | bold italic | alignleft aligncenter alignright | undo redo  ',
          //toolbar2 : 'forecolor backcolor | bullist numlist outdent indent | image |  table | link | code | insertdatetime |  preview | searchreplace | blockquote dialog-example-btn ',
        };
  return (
    <Editor
      disabled={props.readonly || userProfile?.isConsultant || !userProfile?.id}
      onInit={(evt, editor) => (editorRef.current = editor)}
      key={props.questionId}
      init={init}
      apiKey="9et7z3lpglwo8z9xn1vgqqnun691svh0rxojtqx8bd80telz" //techadmin
      value={value}
      onEditorChange={(newValue, editor) => onEditorStateChange(newValue, editor)}
      
    />
  );
};
export default MCEditor;
