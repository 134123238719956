import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InitialStore } from "../store/initialState";
import { IQuestion } from "../utils/defaultData";
import { IAnswer } from "../store/initialState";
import Question from "./Question";
import { saveAnswerService } from "../services/saveAnswerService";
import { NumberOfQuestion } from "../utils/defaultData";
import Loader from "../components/Loader";
import { APP_ROUTES } from "../utils/routeConstant";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { sendAmplitudeData, setAmplitudeUserProperties } from '../utils/amplitideUtils';
import { AMPLITUDE_EVENTS } from "../utils/amplitudeConstants";
interface IQuestions {
  pageData: any;
  publicType?: boolean
}

interface UpdatedAnswer {
  answer: string;
  updatedAt: string;
}
const Questions: React.FC<IQuestions> = (props: IQuestions): JSX.Element => {
  //let answerObject: { [key: string]: string } = {};
  let questionArray: any = [];
  const history = useHistory();
  const userProfile = useSelector((state: InitialStore) => state.userProfile);
  const [loading, setLoading] = useState(true);
  const [answers, setAnswers] = useState<any>([]);
  const [numberOfQuestions, setNumberOfQuestion] = useState<number>(
    parseInt(NumberOfQuestion[props.pageData.name])
  );

  /**
   * 1. api call for answers
   * 2. setting no of questions appearing in any page
   * 3. set answers
   * 4. removed loaders
   */
  useEffect(() => {
    userProfile.profileDataId &&
      saveAnswerService
        .getSavedAnswers(userProfile?.profileDataId)
        .then((answers: any) => {
          setAnswers(answers.data.answers);
          // console.log(answers);
          setLoading(false);
          const mergedQuestionAnswer = calculateQuestion(answers.data.answers);
          const numberOfAnswers = mergedQuestionAnswer.filter(
            (question: IQuestion) => question.answer
          );
          numberOfAnswers.length > NumberOfQuestion[props.pageData.name] &&
            setNumberOfQuestion(numberOfAnswers.length); 
        })
        .catch((dashboard) => {
          const redirectionUrl = localStorage.getItem("isConsultant")
          ? APP_ROUTES.CONSULTANT_LOGIN
          : APP_ROUTES.LOGIN;
          setLoading(true);
          localStorage.removeItem("token");
          localStorage.removeItem("currentUser");
          localStorage.removeItem("isConsultant")
          localStorage.removeItem("pageNumber");
          toast.error("Error in getting Answers");
          history.push(redirectionUrl);
        });
  }, []);

  /**
   *
   * @param answers
   * @returns array of questions and answers
   */
  const calculateQuestion = (answers: any) => {
    let answerObject: { [key: string]: UpdatedAnswer } = {};
    answers &&
      answers.map((answer: any) => {
        if (answer.questionId) {
          answerObject[answer.questionId] = {
            answer: answer?.answer,
            updatedAt: answer?.updatedAt,
          };
        }
        
        return void 0;
      });

    const mergedQuestionAnswer = props.pageData.questions?.map(
      (question: IQuestion) => {
        question["answer"] = answerObject[question.id]?.answer;
        question["updatedAt"] = answerObject[question.id]?.updatedAt;
        return question;
      }
    );
    return mergedQuestionAnswer;
  };

  /**
   *
   * @param givenanswer
   * handling add and update answers in answer array
   */
  const handlePageAnswers = (givenanswer: IAnswer) => {
    const index = answers?.findIndex(
      (answer: IAnswer) => givenanswer.questionId === answer.questionId
    );
    index > -1 && answers.splice(index, 1);
    //console.log(answers, "answers");
    answers.push(givenanswer);
    //console.log(answers);
    setAnswers([...answers]);
  };
  /**
   *
   * adding new questions
   *
   */
  const addQuestion = (e: React.SyntheticEvent) => {
    e.preventDefault();
    sendAmplitudeData(AMPLITUDE_EVENTS.AddQuestion, {publicType:props.publicType});
    setNumberOfQuestion(numberOfQuestions + 1);
  };

  /** adding loader */
  if (loading && !props.publicType) {
    return <Loader />;
  }

  /** getting merged questions*/
  const mergedQuestionAnswer = calculateQuestion(answers);
  const limitedQuestion = mergedQuestionAnswer.slice(0, numberOfQuestions);
  questionArray = props.pageData.addedAllowed
    ? limitedQuestion
    : mergedQuestionAnswer;

  return (
    <>
      {questionArray?.map((question: IQuestion) => {
        return (
          <div
            v-for="item in items"
            key={question.id}
            className="pb-6 last:pb-0"
          >
            <Question
              answer={question.answer}
              updatedAt={question.updatedAt}
              data={question}
              handlePageAnswers={handlePageAnswers}
              publicType={props.publicType}
            />
            <div></div>
          </div>
        );
      })}
      <div className="my-2">
        {props.pageData.addedAllowed && numberOfQuestions < 10 && (
          <div className="flex items-center my-8">
            <div className="border-t flex flex-1"></div>
            <button
              className="px-4 py-2 border text-sm rounded-3xl focus:outline-none bg-brand-lightblue text-brand-darkblue hover:border-brand-grey"
              onClick={(e) => addQuestion(e)}
            >
              + Add another
            </button>
            <div className="border-t flex flex-1"></div>
          </div>
        )}
      </div>
    </>
  );
};
export default Questions;
