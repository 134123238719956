import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IAnswer, InitialStore } from "../store/initialState";
import { uploadMediaService } from "../services/uploadMediaService";
import { saveAnswerService } from "../services/saveAnswerService";
import { sendAmplitudeData } from '../utils/amplitideUtils';
import { AMPLITUDE_EVENTS } from '../utils/amplitudeConstants'; 

interface IDocument {
  questionId: number;
  answer: string;
  saving: string;
  handleSave: (value: string, answer?: IAnswer) => void;
}

const DragDocument = (props: IDocument): JSX.Element => {
  const resumeFile = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [suppertedFileError, setSupportedFileError] = useState({
    noFileSelected: false,
    wrongfileType: false,
  });
  const userProfile = useSelector((state: InitialStore) => state.userProfile);
  function openFileDialog() {
    if (resumeFile && resumeFile?.current) {
      resumeFile.current.click();
    }
  }
  const deleteFile = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (userProfile.profileDataId) {
      saveAnswerService
        .save({
          answer: "",
          profileDataId: userProfile.profileDataId,
          questionId: props.questionId,
        })
        .then((response: any) => {
          props.handleSave("Saved", response.data.answer);
          sendAmplitudeData(AMPLITUDE_EVENTS.SaveAnswer, {answer: response.data.answer, message: 'Resume Deleted', type: 'File'})
          setTimeout(() => props.handleSave(""), 3000);
          setSelectedFileUrl("");
        })
        .catch(() => {
          props.handleSave("Error in saving");
          setTimeout(() => props.handleSave(""), 3000);
        });
    }
  };
  useEffect(() => {
    if (props.answer) {
      setSelectedFileUrl(props.answer);
    }
  }, [props.answer]);
  async function handleUpload(
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    const { files } = event.target;
    document.body.onfocus = function () {
      if (!files || !files.length) {
        props.handleSave("Uploading...");
        setSupportedFileError({
          ...suppertedFileError,
          noFileSelected: true,
        });
      }
      document.body.onfocus = null;
    };
    const validFiles = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (files && files[0]) {
      // Adding loader once upload media starts;
      if (!validFiles.includes(files[0].type)) {
        return setSupportedFileError({
          ...suppertedFileError,
          wrongfileType: true,
        });
      }
      setSupportedFileError({
        ...suppertedFileError,
        noFileSelected: false,
        wrongfileType: false,
      });
      const formData = new FormData();
      formData.append("resume", files[0]);
      await uploadMediaService
        .upload(formData)
        .then((data: any) => {
          setSelectedFileUrl(data?.data.url);
          if (
            userProfile.profileDataId &&
            typeof userProfile.profileDataId === "number"
          ) {
            saveAnswerService

              .save({
                answer: data?.data.url,
                profileDataId: userProfile.profileDataId,
                questionId: props.questionId,
              })
              .then(() => {
                props.handleSave("Saved");
                setTimeout(() => props.handleSave(""), 3000);
              })
              .catch(() => {
                props.handleSave("Error in saving");
                setTimeout(() => props.handleSave(""), 3000);
              });
          }
        })
        .catch((error) => console.log(error));
    }
  }
  return (
    <>
      <div>
        <button
          className="border border-dashed w-full p-4 focus:outline-none"
          disabled={userProfile?.isConsultant}
          onClick={openFileDialog}
        >
          <div className="flex items-center justify-center mx-auto">
            <img alt={'upload icon'} src="/images/upload-file.svg" className="w-6 h-6" />
            <div className="text-brand-torquise pl-4">Browse file </div>
          </div>
        </button>
        <input
          id="fileLoader"
          name="resume"
          onChange={(e) => handleUpload(e)}
          ref={resumeFile}
          type="file"
          tabIndex={-1}
          style={{ display: "none" }}
          accept=".doc,.docx,.pdf"
        />
      </div>
      <div>
        {selectedFileUrl && (
          <div className="flex items-center">
            <span className="text-sm hidden">Previously Uploaded: </span>

            <a
              className="py-1 text-brand-torquise text-sm"
              href={selectedFileUrl}
              download
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              {"Resume"}
            </a>
            <button className="focus:outline-none pl-1" onClick={deleteFile}>
              {" "}
              <img alt={'delete icon'} src={`/images/bin.svg`} className="w-4 h-4" />{" "}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default DragDocument;
