import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { InitialStore, Source } from "../store/initialState";
import DashboardComponent from "./DashboardComponent";
import OverViewSection from "./OverViewSection";
import { APP_ROUTES } from "../utils/routeConstant";
import SchoolPage from "./SchoolPage";
import PublicSchoolSelectionDrawer from "./PublicSchoolSelectionDrawer";
import PublicEssaysPage from "./PublicEssaysPage";
import DashboardSection from "./DashboardSection";
import { hotjar } from "react-hotjar";
declare global {
  interface Window {
    Tawk_API: any;
  }
}

interface IOnboarding {
  task: string;
}

const Onboarding: React.FC<IOnboarding> = (props:IOnboarding): JSX.Element => {
  let { path } = useRouteMatch();
  const dashboard = useSelector((state: InitialStore) => state?.dashboard);
  const [dashboardData, setDashboardData] = useState<any>({});
  const userProfile = useSelector((state: InitialStore) => state.userProfile);
  const hjid = 2598782;
  const hjsv = 6;
  const [hotjarFlag, setHotjarFlag] = useState(false);

  useEffect(() => {
    if (
      !hotjarFlag &&
      process.env.NODE_ENV === "production" &&
      userProfile?.source === Source.Direct &&
      !userProfile.isConsultant
    ) {
      hotjar.initialize(hjid, hjsv);
      setHotjarFlag(true);
    }
    dashboard && setDashboardData({ ...dashboard });
  }, [dashboard]);

  return (
    <>
      <Switch>
        <Route path={APP_ROUTES.ONBOARDING} exact={true}>
          {/* <PublicSchoolSelectionDrawer isOpen={true} /> */}
        </Route>
        <Route path={APP_ROUTES.ONBOARDING_ESSAYS} exact={true}>
          <PublicEssaysPage />
        </Route>
        {dashboardData && dashboardData.categories && dashboardData.categories.length > 0 && 
           <Route exact path={path}>
            <Redirect to={`${path}/${dashboardData?.categories[0].key}`} />
          </Route>
        }
        {dashboardData && dashboardData.categories ? (
          dashboardData.categories?.map((category: any) => {
            return (
              <Route
                key={`${category.key}`}
                path={`${path}/${category.key}`}
                component={() => {
                  return (
                    <DashboardComponent
                      nestedCategories={category.nestedCategories}
                      questions={category.questions}
                      category={category}
                      phase={category.key}
                      publicType={true}
                    />
                  );
                }}
              />
            );
          })
        ) : (
          <></>
        )}
      </Switch>
    </>
  );
};
export default React.memo(Onboarding);
