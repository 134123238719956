/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrls } from "../utils/apiUrls";
import axiosInstance from "../helpers/interceptor";
import { ICommentRequest } from "../store/initialState";

const getComments = async (phase: string): Promise<void> => {
  const unParsedUid = localStorage.getItem('currentUser') || JSON.stringify(0);
  const uid = JSON.parse(unParsedUid);
  const url = uid ? `${apiUrls.GET_COMMENTS}${phase}&uid=${uid}`: `${apiUrls.GET_COMMENTS}${phase}`;
  const { data } = await axiosInstance.get(url);
  return data.response;
};

const postComments = async (request: ICommentRequest): Promise<void> => {
  const unParsedUid = localStorage.getItem('currentUser') || JSON.stringify(0);
  const uid = JSON.parse(unParsedUid);
  const req = uid ? {...request, uid}: {...request};
  const { data } = await axiosInstance.post(apiUrls.POST_COMMENTS, req);
  return data.response;
};
export const commentsService = {
  getComments,
  postComments,
};
