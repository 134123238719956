/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../utils/routeConstant";
import { updateStatusAction } from '../actions/updateStatusAction';
interface IModalProps {
  handleClose: () => void;
}

const SubmitModal = (props: IModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = (): void => {
    dispatch(updateStatusAction.statusUpdateReset());
    history.push(APP_ROUTES.DASHBOARD);
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden px-6 h-full overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="relative overflow-hidden transition-all md:h-auto transform bg-white md:rounded-2xl sm:shadow-xl md:max-w-xl flex flex-col max-h-screen-resp  bg-brand-darkblue"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-brand-darkblue absolute top-0 z-10 bg-white w-full px-6 py-2 flex justify-between border-b border-brand-darkblue text-white">
            <div className="text-white font-bold text-xl pt-3">
              Well done!
            </div>
            <button
              className="text-gray-3 rounded-full focus:outline-none pt-2"
              onClick={handleSubmit}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#ffffff"
              >
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
              </svg>
            </button>
          </div>
          <div className="overflow-y-scroll mt-10 mb-2 pt-6 pb-6 px-6 text-sm text-brand-grey">
          You can book a call with Consultant from Overview page.
          </div>
          <div className="flex justify-end pb-8 px-6">
            <button
              className="w-40 text-sm font-medium border-2 border-brand-torquise p-2 rounded-3xl text-center bg-transparent text-brand-torquise focus:outline-none"
              onClick={handleSubmit}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-white"></div>
    </>
  );
};

export default SubmitModal;
