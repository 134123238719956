/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrls } from "../utils/apiUrls";
import axiosInstance from "../helpers/interceptor";
const dashboard = async (): Promise<void> => {
  const isConsultantSelectedUser = localStorage.getItem('currentUser');
  const url = isConsultantSelectedUser ? `${apiUrls.GET_DASHBOARD_DATA}?uid=${isConsultantSelectedUser}` : apiUrls.GET_DASHBOARD_DATA;
  const { data } = await axiosInstance
    .get(url)
    .then((response: any) => {
      if(response.statusText = 'OK'){
        localStorage.setItem('metaAnswered',response.data.response.metaAnswered);
        localStorage.setItem('metaFeedback',response.data.response.metaFeedback)
        return response;
      } 
    });
  return data.response;
};

const publicSections = async (): Promise<void> => {
  const url = apiUrls.GET_DASHBOARD_PUBLIC_DATA;
  const { data } = await axiosInstance
    .get(url)
    .then((response: any) => {
      if(response.statusText = 'OK') return response;
    });
  return data.response;
};

export const dashboardService = {
  dashboard,
  publicSections
};
