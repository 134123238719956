export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export type REGISTER_REQUEST = typeof REGISTER_REQUEST;

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export type REGISTER_SUCCESS = typeof REGISTER_SUCCESS;

export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export type REGISTER_FAILURE = typeof REGISTER_FAILURE;

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export type LOGIN_REQUEST = typeof LOGIN_REQUEST;

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export type LOGIN_SUCCESS = typeof LOGIN_SUCCESS;

export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export type LOGIN_FAILURE = typeof LOGIN_FAILURE;

export const LOGOUT = 'LOGOUT';
export type LOGOUT = typeof LOGOUT;

export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export type GET_USER_PROFILE_REQUEST = typeof GET_USER_PROFILE_REQUEST;

export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export type GET_USER_PROFILE_SUCCESS = typeof GET_USER_PROFILE_SUCCESS;

export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';
export type GET_USER_PROFILE_FAILURE = typeof GET_USER_PROFILE_FAILURE;

export const REFRESH = 'REFRESH';
export type REFRESH = typeof REFRESH;

export const POST_FORGET_PASSWORD_REQUEST = 'POST_FORGET_PASSWORD_REQUEST';
export type POST_FORGET_PASSWORD_REQUEST = typeof POST_FORGET_PASSWORD_REQUEST;

export const POST_FORGET_PASSWORD_SUCCESS = 'POST_FORGET_PASSWORD_SUCCESS';
export type POST_FORGET_PASSWORD_SUCCESS = typeof POST_FORGET_PASSWORD_SUCCESS;

export const POST_FORGET_PASSWORD_FAILURE = 'POST_FORGET_PASSWORD_FAILURE';
export type POST_FORGET_PASSWORD_FAILURE = typeof POST_FORGET_PASSWORD_FAILURE;

export const POST_RESET_PASSWORD_REQUEST = 'POST_RESET_PASSWORD_REQUEST';
export type POST_RESET_PASSWORD_REQUEST = typeof POST_RESET_PASSWORD_REQUEST;

export const POST_RESET_PASSWORD_SUCCESS = 'POST_RESET_PASSWORD_SUCCESS';
export type POST_RESET_PASSWORD_SUCCESS = typeof POST_RESET_PASSWORD_SUCCESS;

export const POST_RESET_PASSWORD_FAILURE = 'POST_RESET_PASSWORD_FAILURE';
export type POST_RESET_PASSWORD_FAILURE = typeof POST_RESET_PASSWORD_FAILURE;

export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export type REFRESH_SUCCESS = typeof REFRESH_SUCCESS;

export const CONSULTANT_LOGIN_REQUEST = 'CONSULTANT_LOGIN_REQUEST';
export type CONSULTANT_LOGIN_REQUEST = typeof CONSULTANT_LOGIN_REQUEST;

export const CONSULTANT_LOGIN_SUCCESS = 'CONSULTANT_LOGIN_SUCCESS';
export type CONSULTANT_LOGIN_SUCCESS = typeof CONSULTANT_LOGIN_SUCCESS;

export const CONSULTANT_LOGIN_FAILURE = 'CONSULTANT_LOGIN_FAILURE';
export type CONSULTANT_LOGIN_FAILURE = typeof CONSULTANT_LOGIN_FAILURE;

