// routes constants
export const APP_ROUTES = {
  MAIN: '/',
  USERTYPE: '/user',
  LOGIN: '/login',
  LOGIN_DIRECT: '/user/login',
  FACEBOOK: '/facebook/auth',
  DASHBOARD: '/dashboard',
  FEEDBACK: '/feedback',
  THANKYOU:'/thankyou',
  SIGNUP: '/dsgdg33435hgv2324hvj332523vgh/register',
  NOTFOUND: '/not-found',
  SAMPLE: '/sample',
  CONSULTANT_LOGIN: '/consultant/login',
  CONSULTANT_SIGNUP: '/consultant/signup',
  FORGET_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  ALL_USERS: '/all-users',
  CONSULTANT_SCHOOL_ESSAYS: '/c/content/school-content',
  CONSULTANT_RESUME_RESOURCES: '/c/content/resume-resources',
  CONSULTANT_INTERVIEW_RESOURCES: '/c/content/interview-prep',
  CONSULTANT_ONBOARDING_EXTERNAL: 'https://docs.google.com/document/d/16J7MmXjXqmZMw2BlN2y5JNiDl8lzUjozUnGkUI1IFoE/edit?usp=sharing',
  SIGNUP_DIRECT: '/register',
  SIGNIN_DIRECT: '/direct/login',
  SCHOOLS:'/dashboard/schools',
  LATEST_SCHOOLS:'/dashboard/schools-content',
  ONBOARDING:'/start/schools',
  ONBOARDING_ESSAYS:'/start/essays',
  ONBOARDING_SECTIONS:'/start/sections',
  MIRROR_REDIRECT:'/mirror/redirect',
  MEETING_PRIORITY_ORDER:'/meeting/priority/order',
  SCHOOL_DEADLINE_EXTERNAL: 'https://docs.google.com/spreadsheets/d/1cVKkn7cSg4Yongcp1_qMgADSvYadynn-PRC96ijnJFg/edit?usp=sharing',
  LATEST_SAMPLE: '/latest-sample',
};

export const APP_CONSTANTS = {
  PROFILE_DOMAIN: 'https://MBA&Beyond.netlify.app',
  PROFILE_HOST_DOMAIN: 'MBA&Beyond.co',
};
