/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrls } from '../utils/apiUrls';
import axios from 'axios';

const upload= async (media: FormData): Promise<void> => {
  const session =localStorage.getItem('token');
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': session ? `Bearer ${JSON.parse(session)}` : '',
    }
}
  const { data } = await axios.post(apiUrls.POST_UPLOAD_MEDIA, media, config);
  return data.response;
};

export const uploadMediaService = {
  upload
};
 