import * as dashboardConstants from '../constants';
import {
IGetUserFailure,
IGetUserRequest,
IGetUserSuccess,
} from '../actions/actionTypes';
import { initialState, IUserProfile } from '../store/initialState';



export const userProfile = (
  userProfileState = initialState.userProfile,
  action:
    | IGetUserSuccess
    | IGetUserRequest
    | IGetUserFailure
): IUserProfile => {
  switch (action.type) {
    case dashboardConstants.GET_USER_PROFILE_REQUEST:
      return {
        ...userProfileState
      };
    case dashboardConstants.GET_USER_PROFILE_SUCCESS:
      return {
        ...userProfileState, ...action.response,
      };
    case dashboardConstants.GET_USER_PROFILE_FAILURE:
      return {
        ...userProfileState
      };
    default:
      return {...userProfileState} ;
  }
};
