/* eslint-disable react/no-children-prop */
import { Suspense, useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { history } from "./helpers/history";
import { Toaster } from "react-hot-toast";
import Loader from "./components/Loader";
import { toastOptions } from "./utils/toasterOptions";
import ErrorBoundary from "./components/ErrorBoundary";
import { Helmet } from "react-helmet";
import SwitchOptions from "./components/SwitchOptions";
import withClearCache from "./Cache";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import InternetConnectionLost from "./components/InternetConnectionLost";
import ReactGA from 'react-ga4';

ReactGA.initialize("G-J10665BB6T");

function MainApp(): JSX.Element {
  useEffect(() => {
    require("../src/styles/font.css");
  }, []);
  Sentry.init({
    dsn: "https://fb5285c8c6be4e2b80db16efcb6093bc@o1064584.ingest.sentry.io/6055493",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
  const [isOnline, setOnline] = useState(window.navigator.onLine);
  const updateNetwork = () => {
    setOnline(window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    return () => {
      window.removeEventListener("online", updateNetwork);
      window.removeEventListener("offline", updateNetwork);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>MBA&Beyond</title>
        <meta name="description" content="Fill your MBA application" />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      <ErrorBoundary>
        <div>
          <div>
            <Toaster toastOptions={toastOptions} />
          </div>
          <Router history={history}>
            <Suspense fallback={<Loader />}>
              <SwitchOptions />
            </Suspense>
          </Router>
        </div>
        {!isOnline && <InternetConnectionLost handleTryAgain={updateNetwork} />}
      </ErrorBoundary>
    </>
  );
}

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}
export default App;
