export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST';
export type GET_DASHBOARD_REQUEST = typeof GET_DASHBOARD_REQUEST;

export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export type GET_DASHBOARD_SUCCESS = typeof GET_DASHBOARD_SUCCESS;

export const GET_PUBLIC_DASHBOARD_SUCCESS = 'GET_PUBLIC_DASHBOARD_SUCCESS';
export type GET_PUBLIC_DASHBOARD_SUCCESS = typeof GET_PUBLIC_DASHBOARD_SUCCESS;

export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';
export type GET_DASHBOARD_FAILURE = typeof GET_DASHBOARD_FAILURE;