import * as alertConstants from '../constants';
import { ISuccess, IError, IClear } from './actionTypes';

export const alertActions = {
  success,
  error,
  clear,
};

function success(message: string): ISuccess {
  return { type: alertConstants.SUCCESS, message };
}

function error(message: string): IError {
  return { type: alertConstants.ERROR, message };
}

function clear(): IClear {
  return { type: alertConstants.CLEAR };
}
