import * as dashboardConstants from "../constants";
import {
  IGetCommentsFailure,
  IGetCommentsSuccess,
  IGetCommentsRequest,
  IPostCommentsFailure,
  IPostCommentsSuccess,
  IPostCommentsRequest,
} from "../actions/actionTypes";
import { initialState, IComment } from "../store/initialState";

export const comments = (
  commentsState = initialState.comments,
  action:
    | IGetCommentsFailure
    | IGetCommentsRequest
    | IGetCommentsSuccess
    | IPostCommentsFailure
    | IPostCommentsSuccess
    | IPostCommentsRequest
): IComment[] | [] => {
  switch (action.type) {
    case dashboardConstants.GET_COMMENTS_REQUEST:
      return [];
    case dashboardConstants.GET_COMMENTS_SUCCESS:
      return [...action.comment];
    case dashboardConstants.GET_COMMENTS_FAILURE:
      return [];
    case dashboardConstants.POST_COMMENTS_REQUEST:
      return [...commentsState];
    case dashboardConstants.POST_COMMENTS_SUCCESS:
      return [action.comment, ...commentsState];
    case dashboardConstants.POST_COMMENTS_FAILURE:
      return [...commentsState];
    default:
      return [...commentsState];
  }
};
