import * as dashboardConstants from "../constants";
import {
  IPostUpdateStatusFailure,
  IPostUpdateStatusSuccess,
  IPostUpdateStatusRequest,
  IPostUpdateStatusReset,
} from "../actions/actionTypes";
import { initialState, CategoryStatus } from "../store/initialState";

export const submitStatus = (
  categoryStatus = initialState.submitStatus,
  action:
    | IPostUpdateStatusFailure
    | IPostUpdateStatusSuccess
    | IPostUpdateStatusRequest
    | IPostUpdateStatusReset
): string => {
  switch (action.type) {
    case dashboardConstants.POST_UPDATE_STATUS_REQUEST:
      return CategoryStatus.SUBMITING;
    case dashboardConstants.POST_UPDATE_STATUS_SUCCESS:
      return CategoryStatus.SUBMITTED;
    case dashboardConstants.POST_UPDATE_STATUS_FAILURE:
      return CategoryStatus.SUBMITFAILED;
    case dashboardConstants.POST_UPDATE_STATUS_RESET:
      return CategoryStatus.INITIALIZING;
    default:
      return categoryStatus;
  }
};
