import React, { useState, useEffect } from "react";
import QuestionPage from "../pages/QuestionPage";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Drawer from "./Drawer";
import { InitialStore, Source } from "../store/initialState";
import SchoolSelectionDrawer from "./SchoolSelectionDrawer";
import { useSelector } from "react-redux";
import Loader from "./Loader";
import { openInNewTab } from "../utils/helpers";
import { AMPLITUDE_EVENTS } from "../utils/amplitudeConstants";
import { sendAmplitudeData } from "../utils/amplitideUtils";

const DashboardSection = (props: any) => {
  const userProfile = useSelector((state: InitialStore) => state.userProfile);
  const dashboard = useSelector((state: InitialStore) => state.dashboard);
  const [isSchoolSelectionDone, setSchoolSelectionDone] = useState(false);
  const [isFaqOpen, setIsFaqOpen] = useState(false);

  // Public Route history handling
  if(props.publicType) {
    localStorage.setItem('lastVisited', `/${props.phase}/${props.subcategory}`);
    //sendAmplitudeData(AMPLITUDE_EVENTS.PublicContentGatheringSectionsRoute, {path: props.subcategory, publicType: props.publicType})
  }

  const handleDriveOpen = (): void => {
    const finalLink = `https://drive.google.com/drive/u/0/folders/${dashboard?.driveFolderLink}`;
    openInNewTab(finalLink);
  }

  const handleFaqOpen = (): void => {
    setIsFaqOpen(true);
    const body = document.getElementsByTagName('body');
    body[0].classList.add('overflow-hidden');
    sendAmplitudeData(AMPLITUDE_EVENTS.FAQOpened, {publicType:props.publicType});
  };
  const handleFaqClose = (): void => {
    setIsFaqOpen(false);
    const body = document.getElementsByTagName('body');
    body[0].classList.remove('overflow-hidden');
    sendAmplitudeData(AMPLITUDE_EVENTS.FAQClosed, {publicType:props.publicType});
  };

  const handlSchoolSelectionOpen = (): void => {
    setSchoolSelectionDone(true);
    const body = document.getElementsByTagName('body');
    body[0].classList.add('overflow-hidden');
    sendAmplitudeData(AMPLITUDE_EVENTS.MySchoolPageOpened, {publicType:props.publicType});
  };
  const handleSchoolSelectionClose = (): void => {
    setSchoolSelectionDone(false);
    const body = document.getElementsByTagName('body');
    body[0].classList.remove('overflow-hidden');
    sendAmplitudeData(AMPLITUDE_EVENTS.SchoolSelectionClosed, {publicType:props.publicType});
  };
  useEffect(() => {
    if (dashboard?.loading && !userProfile.isConsultant && !props.publicType) {
      let selectedSchool: any = [];
      if (dashboard.selectedSchools) {
        for (const property in dashboard.selectedSchools) {
          selectedSchool.push(dashboard.selectedSchools[property]);
        }
      }
      const isSchoolsAvailable =
        dashboard?.selectedSchools && selectedSchool.length > 0;
      if (userProfile?.source === Source.Direct && !isSchoolsAvailable) {
        handlSchoolSelectionOpen();
      }
    }

    if(dashboard && props.publicType) {
      sendAmplitudeData(AMPLITUDE_EVENTS.PublicContentGatheringSectionsRoute, {path: props.subcategory, publicType: props.publicType})
    }
  }, [userProfile, dashboard]);

  if (!userProfile.source && !props.publicType) {
    return <Loader />;
  }
  
  return (
    <>
     <Sidebar handlSchoolSelectionOpen={handlSchoolSelectionOpen} handleFaqOpen={handleFaqOpen} publicType={props.publicType} />
      <div className="relative md:ml-64">
        <Navbar handleFaqOpen={handleFaqOpen} handleDriveOpen={handleDriveOpen} handleSchoolSelectionOpen={handlSchoolSelectionOpen} publicType={props.publicType} />
        {/* Component */}
        <div className="relative md:pt-24 pb-12 pt-4">
          <QuestionPage
            pageData={props.pageData}
            nestedCategories={props.nestedCategories}
            phase={props.phase}
            openModal={props.openModal}
            handleClose={props.handleClose}
            handleOpenModal={props.handleOpenModal}
            subcategory={props.subcategory}
            publicType={props.publicType}
            userProfileData = {userProfile}
          />
        </div>
        <Drawer isOpen={isFaqOpen} handleClose={handleFaqClose} />
        {/* <SchoolSelectionDrawer isOpen={isSchoolSelectionDone} handleClose={handleSchoolSelectionClose} /> */}
        {/* End Component*/}
      </div>
    </>
  );
};

export default DashboardSection;