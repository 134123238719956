/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { sendAmplitudeData } from "../utils/amplitideUtils";
import { AMPLITUDE_EVENTS } from "../utils/amplitudeConstants";
interface IModalProps {
  handleClose: () => void;
  modalContent: any
}

const SimpleContentModal = (props: IModalProps): JSX.Element => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden px-8 h-full overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="w-max-lg relative transition-all transform bg-white md:rounded-2xl sm:shadow-2xl md:max-w-4xl flex flex-col h-3/4"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="fixed top-0 z-10 bg-white w-full px-8 py-4 flex justify-between  text-brand-gray">
            <div className="text-brand-darkblue font-semibold text-lg">{props.modalContent.heading}</div>
            <button
              className="text-gray-3 rounded-full focus:outline-none"
              onClick={() => {props.handleClose(); sendAmplitudeData(AMPLITUDE_EVENTS.ModalClosed, {title:props.modalContent.heading});}}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#1c1c4c"
              >
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
              </svg>
            </button>
          </div>
          <div className="overflow-scroll text-brand-darkblue py-6 mt-16 mb-6 mx-8 border-t-2 border-brand-lightblue"
          dangerouslySetInnerHTML={{
            __html: props.modalContent.content.replace(/(?:\r\n|\r|\n)/g, "<br>"),
          }}
          >
            
          </div> 
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-white"></div>
    </>
  );
};

export default SimpleContentModal;
