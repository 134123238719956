import React, { useState } from "react";
import { saveAnswerService } from "../services/saveAnswerService";
import { useSelector } from "react-redux";
import { IAnswer, InitialStore } from "../store/initialState";
import { useEffect } from "react";
import { sendAmplitudeData } from "../utils/amplitideUtils";
import { AMPLITUDE_EVENTS } from "../utils/amplitudeConstants";
import { consultantDocumentService } from "../services/consultantDocumentSevice";

interface IInputs {
  [key: string]: string;
}
interface IMultiInput {
  numberofRepetation: number;
  type: string;
  name: string;
  placeholder: string;
  questionId: number;
  answer: string;
  saving: string;
  consultantDataAvailable?: boolean;
  handleSave: (value: string, answer?: IAnswer) => void;
}

const MultiInput = (props: IMultiInput): JSX.Element => {
  const { numberofRepetation, type, name, placeholder } = props;
  const [formLink, setFormLink] = useState<any>([]);
  const array: number[] = Array.from(
    { length: numberofRepetation },
    (v, k) => k
  );
  const success = false;
  const error = false;
  const obj: IInputs = {};
  array.forEach((item) => {
    obj[`${name}${item}`] = "";
  });
  const parsedAnswer = props.answer && JSON.parse(props.answer);
  const [user, setUser] = useState<IInputs>(obj);
  const userProfile = useSelector((state: InitialStore) => state.userProfile);
  useEffect(() => {
    if (props.answer) {
      setUser({ ...parsedAnswer });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.answer]);

  useEffect(() => {
    if (userProfile?.isConsultant && props.consultantDataAvailable) {
      userProfile?.profileDataId &&
        consultantDocumentService
          .getConsultantDocument(userProfile?.profileDataId)
          .then((data) => setFormLink(data));
    }
  }, []);
  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = e.target;
    setUser((user) => ({ ...user, [name]: value }));
  }
  function handleAutoSave(e: React.ChangeEvent<HTMLInputElement>): void {
    props.handleSave("Saving...");
    if (userProfile.profileDataId) {
      saveAnswerService
        .save({
          answer: JSON.stringify(user),
          profileDataId: userProfile.profileDataId,
          questionId: props.questionId,
        })
        .then((response: any) => {
          props.handleSave("Saved", response.data.answer);
          sendAmplitudeData(AMPLITUDE_EVENTS.SaveAnswer, {answer: response.data.answer, message: 'Email references Updated', type: 'Multiinput'});
          setTimeout(() => props.handleSave(""), 3000);
        })
        .catch(() => {
          props.handleSave("Error in saving");
          setTimeout(() => props.handleSave(""), 3000);
        });
    }
  }
  return (
    <>
      {array.map((item, index) => {
        return (
          <div key={item} className="mt-4 mb-8">
            <input
              className={`w-full text-sm border p-4 rounded-sm placeholder-brand-grey text-brand-darkblue focus:outline-none focus:border-brand-torquise hover:border-brand-torquise  ${
                success
                  ? "border-brand-darkblue"
                  : error
                  ? "border-brand-red"
                  : "border-brand-lightblue"
              }`}
              type={type}
              placeholder={`${placeholder}`}
              name={`${name}${index}`}
              value={user[`${name}${index}`]}
              onChange={handleChange}
              onBlur={handleAutoSave}
              autoComplete="none"
              disabled={userProfile?.isConsultant}
            />
            {
              <div
                className={`text-right text-sm pt-2 ${
                  success
                    ? "text-brand-torquise"
                    : error
                    ? "text-brand-red"
                    : "hidden"
                }`}
              >
                Success
              </div>
            }
          </div>
        );
      })}
      {props.consultantDataAvailable && formLink && (
        <div className="truncate">
          {formLink.map((link: any) => {
            return <div className="py-2"> <a className="text-brand-torquise text-sm underline" href={link.formLink} target="_blank">Responses by {link.emailId}</a></div>;
          })}
        </div>
      )}
    </>
  );
};

export default MultiInput;
