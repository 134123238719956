export const AMPLITUDE_EVENTS = {
    Login: 'login',
    Register: 'register',
    DirectRegister: 'direct-register',
    ModalVideoPlay: 'modal-video-play',
    ModalVideoPause: 'modal-video-pause',
    ModalVideoError: 'modal-video-error',
    VideoPlay: 'video-play',
    VideoPause: 'video-pause',
    VideoError: 'video-error',
    AudioPopupClosed: 'audio-popup-closed',
    SaveAnswer: 'save-answer',
    AddQuestion: 'add-question',
    DasboardRoute:'dashboard-route',
    DirectUserRegister: 'direct-user-registered',
    RedirectLoginPageFromRegister: 'redirect-login',
    RedirectRegisterPageFromLogin: 'redirect-from-login',
    SubmitClick: 'submit-click',
    NextClick: 'next-click',
    PreviousClick: 'previous-click',
    ChangePhaseClick: 'change-phase-dashboard-click',
    SampleEssaysClick: 'sample-essays-click',
    ComingSoonClick:'coming-soon-click',
    HIWOpened:'hiw-opened',
    HIWClosed:'hiw-closed',
    FAQOpened:'faq-opened',
    FAQClosed:'faq-closed',
    SchoolSelectionSaved: 'schoolSelection-saved',
    SchoolSelectionClosed: 'schoolselection-closed',
    MySchoolsClicked: 'mySchools-clicked',
    MySchoolsClosed: 'mySchool-closed',
    MySchoolPageOpened: 'mySchool-page-opened',
    ScheduleCallCTA: 'schedule-call-cta',
    SchoolStructurePageRouteVisit: 'schoolStructure-page-route',
    SchoolStructureButtonClick: 'schoolStructure-click',
    SchoolStructureTabClick: 'schoolStructure-tab-school-click',
    SchoolStructureQuestionClick: 'schoolStructure-question-click',
    StructurePageSampleClick: 'schoolStructure-sample-click',
    ModalClosed: 'modal-closed',
    StructurePageWritingSectionClick: 'schoolStructure-writing-section-click',
    PublicSchoolSelectionOpened:'public-school-selection-route',
    PublicSchoolSelectionSaved:'public-school-selection-saved',
    PublicSchoolStructureEssaysOpened:'public-school-structure-page-route',
    PublicContentGatheringSectionsRoute:'public-content-gathering-sections-route',
    PublicSchoolStructureWritingSectionClicked:'public-school-structure-writing-section-clicked',
    PublicLockContentPopupOpen: 'public-lock-content-popup-open',
    PublicLockContentPopupClosed: 'public-lock-content-popup-closed',
    PublicLockContentPopupRegisterClick: 'public-lock-content-register-click',
    PublicLTopRegisterClick: 'public-top-button-register-click',
    Logout: 'logout',
}