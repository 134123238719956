import React, { useState, useEffect } from "react";
import FAQ from "./FAQ";
const faq = [
  {
    question: `How will the tool help me in my application journey?`,
    answer: `The Brainstorming section helps you to introsopect your stories in a structure, discover your strengths & weaknesses and build essay ready content to help you craft your own applications.\nThis process is extensive but what you get at the end of it is a 2.0 version of yourself. Keep the ball rolling!`,
  },
  {
    question: `When will I be able to access the Essays, LOR and Resume section?`,
    answer: `These sections are work-in-progress and the team is working day in and out to bring more beauty to your applications. \n\n We would love to invite you when they come live!`,
  },
  {
    question: `What do I do if I get stuck while writing my anecdotes?`,
    answer: `Just listen and follow the audio carefully. It will direct you throughout your journey with MBA&B. Whenever you get stuck, you may:

      1. Hover your cursor over the ""info"" button and follow the steps listed
      2. Click on ""Read Sample"" to read some samples of anecdotes
      
      Still Stuck? We got you covered mate! Reach out to us at kishan@mbaandbeyond.com!`,
  },
  // {
  //   question: `How do I get my answers reviewed by experts?`,
  //   answer: `Our content gathering specialists are always there for you! Just hit us up here and upgrade your plan. \n They will give you feedback on your stories and the gaps you need to fill in order to make your content application ready.`,
  // },
  {
    question: `How to access team support?`,
    answer: `Just hit us up at kishan@mbaandbeyond.com! 

      Do not stop, keep huddling! We are here for you through thick and thin.`,
  },
];

interface DrawerProps {
  isOpen: boolean;
  handleClose: () => void;
  isFullScreenOn?: boolean;
}
const Drawer = (props: DrawerProps) => {
  const { isOpen } = props;
  return (
    <main
      className={
        " fixed overflow-hidden z-30 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
        (isOpen
          ? " transition-opacity opacity-100 duration-500 translate-x-0  "
          : " transition-all delay-500 opacity-0 translate-x-full  ")
      }
    >
      <section
        className={
          ` w-full bottom-0 absolute  ${props.isFullScreenOn ? '': 'md:pl-64'} h-3/4 shadow-xl delay-400 duration-500 ease-in-out transition-all transform
          ${isOpen ? "translate-y-0 " : "translate-y-full"}`}
      >
        <article className="relative w-full bg-white pb-10 flex flex-col space-y-6 overflow-y-scroll h-full">
          <div className="w-full flex justify-between">
            <header className="px-6 pt-6 pb-0 font-bold text-lg">
              Frequently Asked Questions
            </header>
            <button
              className="text-gray-3 px-6 rounded-full focus:outline-none"
              onClick={() => props.handleClose()}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#1c1c4c"
              >
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
              </svg>
            </button>
          </div>

          {faq.map((eachFaq: any) => (
            <FAQ question={eachFaq.question} answer={eachFaq.answer} />
          ))}
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer"
        onClick={() => props.handleClose()}
      ></section>
    </main>
  );
};

export default Drawer;
