import React, { useState, useEffect, useRef } from "react";
import { userProfileService } from "../services/userProfileService";
import { useSelector, useDispatch } from "react-redux";
import { InitialStore } from "../store/initialState";
import toast from "react-hot-toast";
import { apiUrls } from "../utils/apiUrls";
import moment from "moment";
const axios = require("axios");
var CryptoJS = require("crypto-js");

const products = [
  {
    id: "prod_Mbc0TjEkNcMRrJ",
    price: 5400,
    description: "MBAandBeyond Global - 1 School Package",
  },
  {
    id: "prod_OGKg3OxOEiSwSU",
    price: 7250,
    description: "MBAandBeyond Global - 2 School Package",
  },
  {
    id: "prod_Mbc3ZCTVX41NYn",
    price: 8850,
    description: "MBAandBeyond Global - 3 School Package",
  },
  {
    id: "prod_OGKjLt97hbmIGZ",
    price: 10050,
    description: "MBAandBeyond Global - 4 School Package",
  },
  {
    id: "prod_Mbc5wmxe7m0nKP",
    price: 10850,
    description: "MBAandBeyond Global - 5 School Package",
  },
  {
    id: "prod_OGKrvoqzNEDCAy",
    price: 11600,
    description: "MBAandBeyond Global - 6 School Package",
  },
  {
    id: "prod_Mbc89ORBxxEOeN",
    price: 12350,
    description: "MBAandBeyond Global - 7 School Package",
  },
  {
    id: "prod_ObwUs3xx7WcrRn",
    price: 13000,
    description: "MBAandBeyond Global - 8 School Package",
  },
  {
    id: "prod_Mbc9b6gJ5gYp85",
    price: 13850,
    description: "MBAandBeyond Global - 9 School Package",
  },
  {
    id: "prod_LiEhgSed72iEbu",
    price: 2200,
    description: "MBAandBeyond - 1 School Package",
  },
  {
    id: "prod_ObwX0dv5YZDsn2",
    price: 2750,
    description: "MBAandBeyond - 2 School Package",
  },
  {
    id: "prod_LiEic8TGvvOuv1",
    price: 3300,
    description: "MBAandBeyond - 3 School Package",
  },
  {
    id: "prod_ObwcFCbSuvOyuk",
    price: 3850,
    description: "MBAandBeyond - 4 School Package",
  },
  {
    id: "prod_LiEikjWqH4fAw5",
    price: 4400,
    description: "MBAandBeyond - 5 School Package",
  },
  {
    id: "prod_ObwdRZlPHdrZJ0",
    price: 4950,
    description: "MBAandBeyond - 6 School Package",
  },
  {
    id: "prod_LiEiP8rLLfbD4S",
    price: 5500,
    description: "MBAandBeyond - 7 School Package",
  },
  {
    id: "prod_ObweK23Skxz42X",
    price: 6050,
    description: "MBAandBeyond - 8 School Package",
  },
  {
    id: "prod_LiEjwEU70Hzdef",
    price: 6600,
    description: "MBAandBeyond - 9 School Package",
  },
  {
    id: "prod_PaOAoobofLgJli",
    price: 1000,
    description: "Branding",
  },
  {
    id: "prod_PaOBUEj3sTb0jB",
    price: 1000,
    description: "Essay Editing",
  },
  {
    id: "prod_PaODJaMAnPBaPr",
    price: 1000,
    description: "Interview Prep",
  },
  {
    id: "prod_PaOERR9QiaeBqm",
    price: 1000,
    description: "Value Pack",
  },
];

const ApplicantProfileModal = (props: any): JSX.Element => {
  const dashboard = useSelector((state: InitialStore) => state?.dashboard);
  const userProfile = useSelector((state: InitialStore) => state.userProfile);
  const [profileData, setProfileData] = useState<any>(userProfile?.profileHubspotData);
  const [fullPayment, setFullPayment] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [secondInstallmentLink , setSecondInstallmentLink] = useState();
  const [thirdInstallmentLink, setThirdInstallmentLink] = useState();
  const [fourthInstallmentLink, setFourthInstallmentLink] = useState();
  const [fifthInstallmentLink, setFifthInstallmentLink] = useState();
  const [sixthInstallmentLink, setSixthInstallmentLink] = useState();

  const getInstallmentLinks = async(bodyData : any)=>{
    var cipher = CryptoJS.AES.encrypt(JSON.stringify(bodyData), 'HK2401*MBAB').toString();
    var data = {cipher: cipher};
    const { response } = await fetch(
      `${apiUrls.CHECKOUT_INSTALLMENT}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    ).then((res) => res.json());
    // console.log(response.link);
    return response.link;
  }

  const getUSDtoSGD = async(amount : any)=>{
    try {
      let url = `https://api.apilayer.com/exchangerates_data/convert?to=SGD&from=USD&amount=${amount}`;
      const result = await axios({
        method: "get",
        rediect: "follow",
        headers: {
          apikey: "6qeD7Tl0EL2Vwm9M9BtydiANB5hcjWk4", //harshkumarspj
        },
        url,
      });
      // console.log("result : ", result.data);
      const currentPrice = result.data.result.toFixed();
      return currentPrice;
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    // console.log(dashboard);
    // console.log("Data : ", userProfile);
    // console.log("HubspotData : ", profileData);
    if (profileData.hs_deal_total_installments === "1") {
      setFullPayment(true);
      return;
    }

    if (!profileData.hs_deal_package_type) {
      console.log("No package Found");
      return;
    }
    // let selectedProduct:any;
    for (let i = 0; i < products.length; i++) {
      if (products[i].description === profileData.hs_deal_package_type) {
        setSelectedProduct(products[i]);
        break;
      }
    }
    if (!selectedProduct) {
      console.log("product not found for the customer");
      return;
    }
    // console.log(selectedProduct);

    //Creating Links for Second Installment
    if ( profileData.hs_deal_installment_2_amount && profileData.hs_deal_installment_2_due_date && !profileData.hs_deal_installment_2_status) {
      const resp = async () => {
        try {
          console.log("Here 2nd Installment");
          const currentPrice = await getUSDtoSGD(profileData.hs_deal_installment_2_amount);
          // let currentPrice = 1643.75383; //test price
          console.log("Current Price: ", currentPrice);
          const bodyData = {
            currentPrice: currentPrice,
            pdesc: profileData.hs_deal_package_type,
            contactId: profileData.hs_Contact_id,
            contactEmail: profileData.hs_email,
            installmentId : 2,
          };

          const installmentLink = await getInstallmentLinks(bodyData);
          console.log("secondInstallmentLink : ", installmentLink);
          setSecondInstallmentLink(installmentLink)
        } catch (error) {
          console.log("Error : ", error);
        }
      };
      resp();
    }

    //Creating Links for third Installment
    if ( profileData.hs_deal_installment_3_amount && profileData.hs_deal_installment_3_due_date && !profileData.hs_deal_installment_3_status) {
      const resp = async () => {
        try {
          console.log("Here 3rd Installment");
          const currentPrice = await getUSDtoSGD(profileData.hs_deal_installment_3_amount);
          // let currentPrice = 1600.75383; 
          console.log("Current Price: ", currentPrice);
          const bodyData = {
            currentPrice: currentPrice,
            pdesc: profileData.hs_deal_package_type,
            contactId: profileData.hs_Contact_id,
            contactEmail: profileData.hs_email,
            installmentId: 3,
          };

          const installmentLink = await getInstallmentLinks(bodyData);
          console.log("thirdInstallmentLink : ", installmentLink);
          setThirdInstallmentLink(installmentLink)
        } catch (error) {
          console.log("Error : ", error);
        }
      };
      resp();
    }

    //Creating Links for fourth Installment
    if ( profileData.hs_deal_installment_4_amount && profileData.hs_deal_installment_4_due_date && !profileData.hs_deal_installment_4_status) {
      const resp = async () => {
        try {
          console.log("Here 4th Installment");
          const currentPrice = await getUSDtoSGD(profileData.hs_deal_installment_4_amount);
          // let currentPrice = 1000.75383;
          console.log("Current Price: ", currentPrice);
          const bodyData = {
            currentPrice: currentPrice,
            pdesc: profileData.hs_deal_package_type,
            contactId: profileData.hs_Contact_id,
            contactEmail: profileData.hs_email,
            installmentId: 4,
          };

          const installmentLink = await getInstallmentLinks(bodyData);
          console.log("fourthInstallmentLink : ", installmentLink);
          setFourthInstallmentLink(installmentLink)
        } catch (error) {
          console.log("Error : ", error);
        }
      };
      resp();
    }

    //link for fifth installment
    if ( profileData.hs_deal_installment_5_amount && profileData.hs_deal_installment_5_due_date && !profileData.hs_deal_installment_5_status) {
      const resp = async () => {
        try {
          console.log("Here 5th Installment");
          const currentPrice = await getUSDtoSGD(profileData.hs_deal_installment_5_amount);
          // let currentPrice = 1000.75383;
          console.log("Current Price: ", currentPrice);
          const bodyData = {
            currentPrice: currentPrice,
            pdesc: profileData.hs_deal_package_type,
            contactId: profileData.hs_Contact_id,
            contactEmail: profileData.hs_email,
            installmentId: 5,
          };

          const installmentLink = await getInstallmentLinks(bodyData);
          console.log("fifthInstallmentLink : ", installmentLink);
          setFifthInstallmentLink(installmentLink)
        } catch (error) {
          console.log("Error : ", error);
        }
      };
      resp();
    }

    //link for sixth installment
    if ( profileData.hs_deal_installment_6_amount && profileData.hs_deal_installment_6_due_date && !profileData.hs_deal_installment_6_status) {
      const resp = async () => {
        try {
          console.log("Here 6th Installment");
          const currentPrice = await getUSDtoSGD(profileData.hs_deal_installment_6_amount);
          // let currentPrice = 1000.75383;
          console.log("Current Price: ", currentPrice);
          const bodyData = {
            currentPrice: currentPrice,
            pdesc: profileData.hs_deal_package_type,
            contactId: profileData.hs_Contact_id,
            contactEmail: profileData.hs_email,
            installmentId: 6,
          };

          const installmentLink = await getInstallmentLinks(bodyData);
          console.log("sixthInstallmentLink : ", installmentLink);
          setSixthInstallmentLink(installmentLink)
        } catch (error) {
          console.log("Error : ", error);
        }
      };
      resp();
    }

  }, []);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden px-6 h-full overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="mt-4 relative overflow-hidden transition-all md:h-auto transform  md:rounded-2xl sm:shadow-xl md:max-w-6xl flex flex-col max-h-screen-resp  bg-brand-darkblue w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-brand-darkblue absolute top-0 z-10 bg-white w-full px-8 pt-3 flex justify-between border-b border-brand-darkblue tex-white mt-4">
            <div className="text-white font-bold text-xl pt-2"> </div>
            <button
              className="text-gray-3 rounded-full focus:outline-none"
              onClick={() => props.handleClose()}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#ffffff"
              >
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
              </svg>
            </button>
          </div>

          <div className="mb-2 py-4 px-8 mt-8">
            <div className="pt-2">
              <div className="flex flex-row justify-center items-center ">
                <p className="text-white font-bold text-lg pt-2 border-2 border-brand-torquise px-4 pb-1 rounded-3xl">
                  Your Payment Status
                </p>
              </div>

              <p className="text-white mt-2">
                {profileData?.hs_deal_package_type
                  ? `Package Amount :  ${profileData?.hs_deal_amount} USD | Total Installment(s) : ${profileData?.hs_deal_total_installments}`
                  : ""}
              </p>

              {profileData?.hs_deal_installment_1_status &&
              profileData?.hs_deal_installment_1_amount &&
              profileData.hs_installment_1_date ? (
                <>
                  <p className="text-white mt-2">
                    First Installment Amount -{" "}
                    {profileData?.hs_deal_installment_1_amount} USD | Paid
                  </p>
                </>
              ) : (
                <>
                  <p className="text-white mt-2">
                    {profileData?.hs_deal_installment_1_amount ? `First Installment Amount - ${profileData?.hs_deal_installment_1_amount} USD | Due` : ' '} 
                  </p>{" "}
                </>
              )}

              {profileData?.hs_deal_installment_2_status &&
              profileData?.hs_deal_installment_2_amount &&
              profileData.hs_installment_2_date ? (
                <>
                  <p className="text-white mt-2">
                    Second Installment Amount -{" "}
                    {profileData?.hs_deal_installment_2_amount} USD | Paid
                  </p>
                </>
              ) : (
                <>
                  {secondInstallmentLink &&
                    !profileData?.hs_deal_installment_2_status &&
                    profileData?.hs_deal_installment_2_amount && (
                      <>
                        <p className="text-white mt-1 py-1">
                          Second Installment Amount -{" "}
                          {profileData?.hs_deal_installment_2_amount} USD | Due
                          ({profileData?.hs_deal_installment_2_due_date})
                        </p>
                        <a
                          className="py-1 text-brand-torquise text-sm underline"
                          href={secondInstallmentLink}
                          target="_blank"
                        >
                          <button
                            type="submit"
                            className="border-2 font-medium  bg-blue-900 p-2 rounded-3xl text-center w-full text-white focus:outline-none"
                          >
                            {"Second Installment Link"}
                          </button>
                        </a>
                      </>
                    )}
                </>
              )}

              {profileData?.hs_deal_installment_3_status &&
              profileData?.hs_deal_installment_3_amount &&
              profileData.hs_installment_3_date ? (
                <>
                  <p className="text-white mt-2">
                    Third Installment Amount -{" "}
                    {profileData?.hs_deal_installment_3_amount} USD | Paid
                  </p>
                </>
              ) : (
                <>
                  {thirdInstallmentLink &&
                    !profileData?.hs_deal_installment_3_status &&
                    profileData?.hs_deal_installment_3_amount && (
                      <>
                        <p className="text-white mt-1 py-1">
                          Third Installment Amount -{" "}
                          {profileData?.hs_deal_installment_3_amount} USD | Due
                          ({profileData?.hs_deal_installment_3_due_date})
                        </p>
                        <a
                          className="py-1 text-brand-torquise text-sm underline"
                          href={thirdInstallmentLink}
                          target="_blank"
                        >
                          <button
                            type="submit"
                            className="border-2 font-medium  bg-blue-900 p-2 rounded-3xl text-center w-full text-white focus:outline-none"
                          >
                            {"Third Installment Link"}
                          </button>
                        </a>
                      </>
                    )}
                </>
              )}

              {profileData?.hs_deal_installment_4_status &&
              profileData?.hs_deal_installment_4_amount &&
              profileData.hs_installment_4_date ? (
                <>
                  <p className="text-white mt-2">
                    Fourth Installment Amount -{" "}
                    {profileData?.hs_deal_installment_4_amount} USD | Paid
                  </p>
                </>
              ) : (
                <>
                  {fourthInstallmentLink &&
                    !profileData?.hs_deal_installment_4_status &&
                    profileData?.hs_deal_installment_4_amount && (
                      <>
                        <p className="text-white mt-1 py-1">
                          Fourth Installment Amount -{" "}
                          {profileData?.hs_deal_installment_4_amount} USD | Due
                          ({profileData?.hs_deal_installment_4_due_date})
                        </p>
                        <a
                          className="py-1 text-brand-torquise text-sm underline"
                          href={fourthInstallmentLink}
                          target="_blank"
                        >
                          <button
                            type="submit"
                            className="border-2 font-medium  bg-blue-900 p-2 rounded-3xl text-center w-full text-white focus:outline-none"
                          >
                            {"Fourth Installment Link"}
                          </button>
                        </a>
                      </>
                    )}
                </>
              )}

              {profileData?.hs_deal_installment_5_status &&
              profileData?.hs_deal_installment_5_amount &&
              profileData.hs_installment_5_date ? (
                <>
                  <p className="text-white mt-2">
                    Fifth Installment Amount -{" "}
                    {profileData?.hs_deal_installment_5_amount} USD | Paid
                  </p>
                </>
              ) : (
                <>
                  {fifthInstallmentLink &&
                    !profileData?.hs_deal_installment_5_status &&
                    profileData?.hs_deal_installment_5_amount && (
                      <>
                        <p className="text-white mt-1 py-1">
                          Fifth Installment Amount -{" "}
                          {profileData?.hs_deal_installment_5_amount} USD | Due
                          ({profileData?.hs_deal_installment_5_due_date})
                        </p>
                        <a
                          className="py-1 text-brand-torquise text-sm underline"
                          href={fifthInstallmentLink}
                          target="_blank"
                        >
                          <button
                            type="submit"
                            className="border-2 font-medium  bg-blue-900 p-2 rounded-3xl text-center w-full text-white focus:outline-none"
                          >
                            {"Fifth Installment Link"}
                          </button>
                        </a>
                      </>
                    )}
                </>
              )}

              {profileData?.hs_deal_installment_6_status &&
              profileData?.hs_deal_installment_6_amount &&
              profileData.hs_installment_6_date ? (
                <>
                  <p className="text-white mt-2">
                    Sixth Installment Amount -{" "}
                    {profileData?.hs_deal_installment_6_amount} USD | Paid
                  </p>
                </>
              ) : (
                <>
                  {sixthInstallmentLink &&
                    !profileData?.hs_deal_installment_6_status &&
                    profileData?.hs_deal_installment_6_amount && (
                      <>
                        <p className="text-white mt-1 py-1">
                          Sixth Installment Amount -{" "}
                          {profileData?.hs_deal_installment_6_amount} USD | Due
                          ({profileData?.hs_deal_installment_6_due_date})
                        </p>
                        <a
                          className="py-1 text-brand-torquise text-sm underline"
                          href={sixthInstallmentLink}
                          target="_blank"
                        >
                          <button
                            type="submit"
                            className="border-2 font-medium  bg-blue-900 p-2 rounded-3xl text-center w-full text-white focus:outline-none"
                          >
                            {"Sixth Installment Link"}
                          </button>
                        </a>
                      </>
                    )}
                </>
              )}

              {!secondInstallmentLink &&
                !thirdInstallmentLink &&
                !fourthInstallmentLink &&
                !fifthInstallmentLink &&
                !sixthInstallmentLink && (
                  <p className="text-white font-bold text-xl pt-2 px-4 pb-1">
                    No more payment info found !!!
                  </p>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-white"></div>
    </>
  );
};

export default ApplicantProfileModal;
