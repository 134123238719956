import React from "react";
import { createPopper } from "@popperjs/core";
interface ITooltip {
  info: string;
}

const Tooltip: React.FC<ITooltip> = (props: ITooltip): JSX.Element => {
  const [popoverShow, setPopoverShow] = React.useState(false);
  const btnRef = React.useRef<HTMLSpanElement| null>(null);
  const popoverRef = React.useRef<HTMLDivElement | null>(null);
  const openPopover = () => {
    if (btnRef && btnRef.current && popoverRef?.current) {
      createPopper(btnRef?.current, popoverRef.current, {
        placement: "right",
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              altAxis: true,
              padding: 40,
            },
          },
        ],
      });
      setPopoverShow(true);
    }
  };
  const closePopover = () => {
    setPopoverShow(false);
  };

  return (
    <>
      <span className="my-auto mx-auto cursor-pointer">
        <span
          className={`focus:outline-none`}
          onMouseEnter={openPopover}
          onMouseLeave={closePopover}
          ref={btnRef}
        >
          <img src="/images/info.svg" className="inline pb-0.5" alt={'info icon'}/>
        </span>
        <div
          className={`${
            popoverShow ? "" : "hidden "
          } bg-brand-lightblue ml-10 block z-50 font-normal max-w-xs lg:max-w-lg text-left no-underline rounded-3xl`}
          ref={popoverRef}
        >
          <div
            className={`px-4 py-2 border-none text-xs font-medium cursor-pointer text-brand-darkblue`}
          >
            {props.info}
          </div>
        </div>
      </span>
    </>
  );
};

export default Tooltip;