import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IComment } from "../store/initialState";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { commentsService } from "../services/commentService";
import Loader from "./Loader";
import { InitialStore } from "../store/initialState";
import moment from "moment";

interface ICommentsProps {
  heading: string;
}

export default function Comments(props: ICommentsProps) {
  //const comments = useSelector((store: InitialStore) => store.comments);
  const userProfile = useSelector((store: InitialStore) => store.userProfile);
  const success = true;
  const error = false;
  const location = useLocation().pathname;
  const category = location.split("/");
  const [comments, setComments] = useState<IComment[] | []>([]);
  const [comment, setComment] = useState("");
  useEffect(() => {
    if (userProfile.profileDataId) {
      commentsService
        .getComments(
          `?category=${
            category.length === 3
              ? category[category.length - 1]
              : category[category.length - 2]
          }${
            category.length === 4
              ? "&subCategory=" + category[category.length - 1]
              : ""
          }${"&profileDataId=" + userProfile.profileDataId}`
        )
        .then((response: any) => {
          if ((response.statusText = "OK")) return response;
        })
        .then((response) => {
          response.data && response.data
            ? setComments([...comments, ...response.data])
            : void 0;
        })
        .catch(() => toast.error("Error in loading Comments"));
    }
    return () => {
      setComments([]);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const categoryItem =
    category.length === 3
      ? category[category.length - 1]
      : category[category.length - 2];
  const subCategoryItem =
    category.length === 4 ? category[category.length - 1] : "";
  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    if (userProfile.profileDataId) {
      commentsService
        .postComments({
          profileDataId: userProfile.profileDataId,
          comment: comment,
          category:
            category.length === 3
              ? category[category.length - 1]
              : category[category.length - 2],
          subCategory:
            category.length === 4 ? category[category.length - 1] : "",
        })
        .then((response: any) => {
          if ((response.statusText = "OK")) return response;
        })
        .then((response) => {
          toast.success("Comment added successfully");
          setComment("");
          response.data && response.data
            ? setComments([response.data, ...comments])
            : void 0;
        })
        .catch(() => toast.error("Error in adding Comment"));
    }
  };
  return (
    <>
      <div className="flex border-b p-6 -mr-6 space-x-2">
        <img src="/images/comments.svg" className="w-6 h-6" alt="comments" />
        <div className="text-brand-darkblue">{"Comments"}</div>
      </div>
      <div className="pl-6 pt-4">
        <div className="border-b pb-2">
          <input
            className={`my-4 w-full text-sm border py-3 px-4 rounded-sm placeholder-brand-grey text-brand-darkblue border-brand-lightblue focus:outline-none focus:border-brand-torquise hover:border-brand-torquise  ${
              success
                ? "border-brand-grey"
                : error
                ? "border-brand-red"
                : "border-brand-lightblue"
            }`}
            type="text"
            name="comments"
            placeholder="Write Comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <div className="flex justify-end">
            <button
              className="my-2 border text-sm font-medium border-brand-grey py-2 px-4 rounded-3xl text-center text-brand-grey hover:text-brand-torquise hover:border-brand-torquise focus:outline-none"
              onClick={(e) => handleSubmit(e)}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="">
          {comments && comments.length > 0 && (
            <div className="text-brand-grey py-2 mt-3 hidden">
              {props.heading}
            </div>
          )}
          <div className="text-brand-grey py-0.5 mt-3">{""}</div>
          {comments ? (
            comments.map((comment: IComment) => {
              const date = moment(comment.createdAt).format("MMM, YYYY");
              return (
                <div key={subCategoryItem ? `${categoryItem}-${subCategoryItem}-${comment.id}` : `${categoryItem}-${comment.id}`} className="border-b py-2">
                  <div  className="py-2 flex items-center">
                    <div className="w-10 h-10 mr-2 bg-brand-torquise text-white inline-flex items-center justify-center rounded-full">
                      <img
                        alt="user"
                        className="m-2"
                        src={"/images/user.svg"}
                      />
                    </div>
                    <div>
                      <div className="text-brand-darkblue text-base">
                        {comment.name}
                      </div>
                      <div className="text-brand-grey text-sm">{date}</div>
                    </div>
                  </div>
                  <div className="text-brand-grey py-4 text-sm">
                    {comment.comment}
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
