export const POST_UPDATE_STATUS_REQUEST = 'POST_UPDATE_STATUS_REQUEST';
export type POST_UPDATE_STATUS_REQUEST = typeof POST_UPDATE_STATUS_REQUEST;

export const POST_UPDATE_STATUS_SUCCESS = 'POST_UPDATE_STATUS_SUCCESS';
export type POST_UPDATE_STATUS_SUCCESS = typeof POST_UPDATE_STATUS_SUCCESS;

export const POST_UPDATE_STATUS_FAILURE = 'POST_UPDATE_STATUS_FAILURE';
export type POST_UPDATE_STATUS_FAILURE = typeof POST_UPDATE_STATUS_FAILURE;

export const POST_UPDATE_STATUS_RESET = 'POST_UPDATE_STATUS_RESET';
export type POST_UPDATE_STATUS_RESET = typeof POST_UPDATE_STATUS_RESET;

export const POST_UPDATE_CONSULTANT_STATUS_REQUEST = 'POST_UPDATE_CONSULTANT_STATUS_REQUEST';
export type POST_UPDATE_CONSULTANT_STATUS_REQUEST = typeof POST_UPDATE_CONSULTANT_STATUS_REQUEST;

export const POST_UPDATE_CONSULTANT_STATUS_SUCCESS = 'POST_UPDATE_CONSULTANT_STATUS_SUCCESS';
export type POST_UPDATE_CONSULTANT_STATUS_SUCCESS = typeof POST_UPDATE_CONSULTANT_STATUS_SUCCESS;

export const POST_UPDATE_CONSULTANT_STATUS_FAILURE = 'POST_UPDATE_CONSULTANT_STATUS_FAILURE';
export type POST_UPDATE_CONSULTANT_STATUS_FAILURE = typeof POST_UPDATE_CONSULTANT_STATUS_FAILURE;