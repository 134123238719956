/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InitialStore } from "../store/initialState";
import { updateStatusAction } from "../actions/updateStatusAction";
import { ICategory, Status } from "../utils/defaultData";
interface IModalProps {
  handleClose: () => void;
}

const ChangeStatusModal = (props: IModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const dashboard = useSelector((state: InitialStore) => state?.dashboard);
  const userProfile = useSelector((state: InitialStore) => state.userProfile);
  const [dashboardData, setDashboardData] = useState<any>({});
  const [tempSelected, setTempSelected] = useState("");
  const [input, setInput] = useState({
    category: "",
    status: "",
  });
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.currentTarget;
    setInput({ ...input, [name]: value });
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.currentTarget;
    setInput({ ...input, [name]: value });
    setTempSelected(value)
  };
  const options =
    dashboardData &&
    dashboardData.categories?.map((category: ICategory) => {
      return category;
    });
  useEffect(() => {
    dashboard && setDashboardData({ ...dashboard });
    const options =
      dashboard &&
      dashboard.categories?.map((category: ICategory) => {
        return category;
      });
    dashboard &&
      setInput({
        category: options && options.length ? options[0].key : "",
        status: Status.LOCKED,
      });
  }, [dashboard]);
  const handleSubmit = (e: React.SyntheticEvent): void => {
    e.preventDefault();
    if (userProfile.profileDataId && input.category && input.status && tempSelected) {
      dispatch(
        updateStatusAction.updateByConsultant({
          profileDataId: userProfile.profileDataId,
          category: input.category,
          status: input.status,
        })
      );
      props.handleClose();
    }
    else {
      alert("Please select a value")
    }
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden px-6 h-full overflow-y-scroll fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="relative overflow-hidden transition-all md:h-auto transform bg-white md:rounded-2xl sm:shadow-xl md:max-w-xl flex flex-col max-h-screen-resp  bg-brand-darkblue"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-brand-darkblue absolute top-0 z-10 bg-white w-full px-8 py-4 flex justify-between border-b border-brand-darkblue tex-white">
            <div className="text-white font-bold text-xl pt-2">
              {"Change Status"}
            </div>
            <button
              className="text-gray-3 rounded-full focus:outline-none"
              onClick={() => props.handleClose()}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#ffffff"
              >
                <path d="M0 0h24v24H0V0z" fill="none"></path>
                <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
              </svg>
            </button>
          </div>
          <div className="overflow-y-scroll mt-20 mb-3 py-6 px-8">
            <select
              name={"category"}
              className="w-full border bg-white rounded px-3 py-2 outline-none"
              onChange={handleChange}
            >
              {options &&
                options.map((option: any) => {
                  return (
                    <option className="py-1" value={option.key}>
                      {option.key} - {option.name}
                    </option>
                  );
                })}
            </select>
            <select
              name={"status"}
              className="w-full border bg-white rounded px-3 py-2 outline-none mt-10"
              onChange={handleStatusChange}
              required
              value={tempSelected}
            >
              <option className="py-1" value="" selected disabled></option>
              <option className="py-1">{Status.LOCKED}</option>
              <option className="py-1">{Status.UNLOCKED}</option>
              <option className="py-1">{Status.INPROGESS}</option>
              <option className="py-1">{Status.COMPLETED}</option>
            </select>
            <p className="text-white mt-1">
              Current Value -{" "}
              {options &&
                options.length > 0 &&
                options.find((o: { key: string }) => o.key === input.category)
                  ?.status}
            </p>
            <button
              type="submit"
              className="mt-10 border-2 font-medium text-sm border-brand-torquise p-2 rounded-3xl text-center w-36 text-brand-torquise hover:bg-brand-torquise hover:text-white focus:outline-none mt-2"
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-80 fixed inset-0 z-40 bg-white"></div>
    </>
  );
};

export default ChangeStatusModal;
