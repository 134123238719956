import React, { useState, useRef, useEffect } from 'react';
import { interviewPrepService } from '../services/interviewPrepServices';
import toast from "react-hot-toast";


const InterviewPrep = ({questionId, applicantId, questionName, questionVideoURL,isConsultant}: any) => {

    const [readOnly, setReadOnly] = useState<boolean>();
    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [showBrowse, setShowBrowse] = useState(true);
    const [isVideoAvailable, setIsVideoAvailable] = useState(false);
    const [showUploadSection, setShowUploadSection] = useState(true);
    const [videoURL, setVideoURL] = useState(questionVideoURL);
    const [videoOrientation, setVideoOrientation] = useState("");
    const [buttonsVisible, setButtonsVisible] = useState(false);
    const videoFile = useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        if(isConsultant == true){
            setReadOnly(false)
        } else {
        setReadOnly(true)
        }
    })

    useEffect(()=>{
        if(questionVideoURL){
            setIsVideoAvailable(true);
            setShowUploadSection(false);
        }
    })

    function openFileDialog() {
        if (videoFile && videoFile?.current) {
          videoFile.current.click();
        }
    }

    useEffect(()=>{
        if (file) {
            setVideoURL(URL.createObjectURL(file));
            setShowBrowse(false)
            setShowPreview(!showPreview)
        }
    },[file])

    const cancelSelectedVideo = () => {
        setFile(null);
        setShowUploadSection(true);
        setShowBrowse(true)
        setShowPreview(false)
    }

    const getVideoOrientation = (video: HTMLVideoElement): string => {
        const { videoWidth, videoHeight } = video;
        return videoWidth > videoHeight ? 'landscape' : 'portrait';
    };

    const handleFileChange = (event: any) => {
        const selectedFile = event.target.files[0];

        if (selectedFile && selectedFile.type.startsWith('video/')) {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.src = URL.createObjectURL(selectedFile);

            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);

                if (video.duration <= 300) {
                    setFile(selectedFile);
                    setButtonsVisible(true);
                    setVideoOrientation(getVideoOrientation(video))
                    setErrorMessage('')
                } else {
                    setErrorMessage('Time limit exceeded.');
                    setFile(null);
                }
            };
        } else {
            setErrorMessage('Please select a video file.');
            setFile(null);
        }
    };

    const uploadVideo = async(file:any) => {
        if(window.confirm('Make sure you reviewed your submission?')) {
    
            const videoData = new FormData();
            videoData.append('video', file);

            const questionData = {
                questionId: questionId,
                questionName: questionName,
                applicantId: applicantId,
                videoOrientation: videoOrientation
            };

            const loadingToastId = toast.loading("Please wait for a couple of minutes. We are moving closer your goal. Just make sure your internet connection is stable.");
            setButtonsVisible(false);

            try {
                const response = await interviewPrepService.uploadInterviewPrepVideo(videoData, questionData);
                toast.dismiss(loadingToastId);
                toast.success("Video Saved Successfully");
            } catch (error) {
                toast.dismiss(loadingToastId);
                toast.error("There's an error while saving video");
                setButtonsVisible(true);
            }
        }
    }


    return (
        <>
            {isVideoAvailable && questionVideoURL && (
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center'}}>
                    <video src={videoURL} controls controlsList="nodownload" style={{ display: 'block', borderRadius: '6px' }}/>
                </div>
            )}

            {readOnly && showUploadSection && (
                <>
                    { showBrowse && (
                        <div>
                            <button
                            className="border border-dashed rounded-md w-full p-4 focus:outline-none"
                            // disabled={userProfile?.isConsultant}
                            onClick={openFileDialog}
                            >
                                <div className="flex items-center justify-center mx-auto">
                                    <img alt={'upload icon'} src="/images/upload-file.svg" className="w-6 h-6" />
                                    <div className="text-brand-torquise pl-4">Browse file </div>
                                </div>
                            </button>
                            <input
                                onChange={handleFileChange}
                                ref={videoFile}
                                type="file"
                                style={{ display: "none" }}
                                accept="video/*"
                            />
                        </div>
                    )}

                    {errorMessage && <p style={{ color: 'red', textAlign:'end', fontSize:'14px' }}>{errorMessage}</p>}

                    {showPreview && (
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'center'}}>
                            <video src={videoURL} controls controlsList="nodownload" style={{ display: 'block', borderRadius: '6px', maxHeight:'500px'}} />
                        </div>
                    )}


                    {file && buttonsVisible && (
                        <>
                        <div className="videoUploadCancelButtons">
                            <button className="videoCancelButton" onClick={ () => cancelSelectedVideo()}>Cancel</button>
                            <button className="videoUploadButton" onClick={ () => uploadVideo(file)}>Upload Video</button>
                        </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default InterviewPrep;
