import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';
import toast from 'react-hot-toast';
const axiosInstance: AxiosInstance = axios.create();
axios.defaults.baseURL = '';
axiosInstance.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
  console.log({config})
  let override = false;
  // check if internal or external API
  if(config.url && config.url.includes("populate")) {
    override = true;
  }
  const userSessionActive = localStorage.getItem('token');
  config.headers['Content-Type'] = 'application/json';
  if (userSessionActive && !override) {
    config.headers['Authorization'] = `Bearer ${JSON.parse(userSessionActive)}`;
  }
  return config;
});
axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,
  (error: AxiosError): Promise<never> => {
    if (error.message === 'Network error' && !error.response) {
      toast.error('Network error');
    }
    if (error.response) {
      const { status, data } = error.response;
     if (status === 500) {
        toast.error('Internal Server Error');
      }
    }
    return Promise.reject(error.response);
  },
);
export default axiosInstance;
