import { useState, useEffect, useRef } from "react";
import '../styles/userJourney.css';


const DropDown = ({readOnly, label, placeholder, options, selected, setSelected}:any) => {

    const [isActive, setIsActive] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
        if (dropdownRef.current?.contains(event.target)) {
            return;
        }
        setIsActive(false);
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return(
        <>
        <div className="dropdown" ref={dropdownRef} >
            <p className="iC_fields_onboardingInfo_dropdownHeading" >{label}</p>
            <div className={ !readOnly ? "dropdown_button curson_pointer" : "dropdown_button curson_not_selected"} onClick={()=> setIsActive(!isActive)} >{selected ? selected : placeholder}
                <span>
                    {
                        !readOnly && (
                            <>
                            {isActive ? 
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                                    <path d="M13 7L7 1L1 7" stroke="#8C8C8C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                                    <path d="M1 1.5L7 7.5L13 1.5" stroke="#8C8C8C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg> 
                            }
                            </>
                        )
                    }
                </span>
            </div>
            {isActive && !readOnly && (
                <div className="dropdown_content">
                    { options.map((option: any, index: any)=>{
                        return (
                            <div key={index} onClick={(e) => {
                                    setSelected(option?.name || option)
                                    setIsActive(false)
                                }}
                                className="dropdown_option" >
                                {option?.name || option}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
        </>
    );
}

export default DropDown;
