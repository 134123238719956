import * as userConstants from "../constants";
import { updateStatusService } from "../services/updateStatusService";
import { alertActions } from "./alertAction";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { IApiError, IStatusUpdateRequest, IStatusUpdateConsultantRequest , IDeadlineUpdateConsultantRequest } from "../store/initialState";
import { AnyAction } from "redux";
import {
  IPostUpdateStatusRequest,
  IPostUpdateStatusSuccess,
  IPostUpdateStatusFailure,
  IPostUpdateStatusReset,
  IPostUpdateConsultantStatusRequest,
  IPostUpdateConsultantStatusSuccess,
  IPostUpdateConsultantStatusFailure
} from "./actionTypes";
import toast from "react-hot-toast";
export const statusUpdateRequest = (status: IStatusUpdateRequest): IPostUpdateStatusRequest => {
  return { type: userConstants.POST_UPDATE_STATUS_REQUEST, status };
};

export const statusUpdateSuccess = (): IPostUpdateStatusSuccess => {
  return { type: userConstants.POST_UPDATE_STATUS_SUCCESS};
};

export const statusUpdateFailure = (error: IApiError): IPostUpdateStatusFailure => {
  return { type: userConstants.POST_UPDATE_STATUS_FAILURE, error };
};

export const statusUpdateConsultantRequest = (status: IStatusUpdateConsultantRequest): IPostUpdateConsultantStatusRequest => {
  return { type: userConstants.POST_UPDATE_CONSULTANT_STATUS_REQUEST, status };
};

export const statusUpdateConsultantSuccess = (): IPostUpdateConsultantStatusSuccess => {
  return { type: userConstants.POST_UPDATE_CONSULTANT_STATUS_SUCCESS};
};

export const statusUpdateConsultantFailure = (error: IApiError): IPostUpdateConsultantStatusFailure => {
  return { type: userConstants.POST_UPDATE_CONSULTANT_STATUS_FAILURE, error };
};

export const statusUpdateReset = (): IPostUpdateStatusReset => {
  return { type: userConstants.POST_UPDATE_STATUS_RESET };
};

export const update = (
    status: IStatusUpdateRequest
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    const loadingToastId = toast.loading("Submitting");
    await dispatch(statusUpdateRequest(status));
    updateStatusService
      .update(status)
      .then(() => {
        dispatch(statusUpdateSuccess());
        dispatch(alertActions.success("successful"));
        toast.dismiss(loadingToastId);
        //toast.success("successful");
      })
      .catch((error: any) => {
        dispatch(statusUpdateFailure(error.data));
        toast.dismiss(loadingToastId);
        dispatch(alertActions.error(error.data));
      });
  };
};

export const updateByConsultant = (
  status: IStatusUpdateConsultantRequest
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
  const loadingToastId = toast.loading("Submitting");
  dispatch(statusUpdateConsultantRequest(status));
  await updateStatusService
    .updateByConsultant(status)
    .then(() => {
      dispatch(statusUpdateConsultantSuccess());
      dispatch(alertActions.success("successful"));
      toast.dismiss(loadingToastId);
      toast.success("Status changed Successfully");
    })
    .catch((error: any) => {
      dispatch(statusUpdateConsultantFailure(error.data));
      toast.dismiss(loadingToastId);
      dispatch(alertActions.error(error.data));
    });
};
};

export const deadlineUpdateByConsultant = (
  deadline: IDeadlineUpdateConsultantRequest
): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<void> => {
    // console.log("Here exec");
    // console.log(deadline);
    const loadingToastId = toast.loading("Submitting");
    // dispatch(statusUpdateConsultantRequest(status));
    await updateStatusService
      .deadlineUpdateByConsultant(deadline)
      .then(() => {
        dispatch(statusUpdateConsultantSuccess());
        dispatch(alertActions.success("successful"));
        toast.dismiss(loadingToastId);
        toast.success("Deadline updated Successfully");
      })
      .catch((error: any) => {
        dispatch(statusUpdateConsultantFailure(error.data));
        toast.dismiss(loadingToastId);
        dispatch(alertActions.error(error.data));
      });
  };
};

export const updateStatusAction = {
  update,
  statusUpdateReset,
  updateByConsultant,
  deadlineUpdateByConsultant,
};
