import amplitude from 'amplitude-js';

export const initAmplitude = () => {
  
  var key = '3b112156a92138415d0aa3db6814a7df';
  // Hack to bypass Staging environment hits
  if(process.env.NODE_ENV === 'production' && process.env.REACT_APP_MBA_BEYOND_DOMAIN == 'https://console.mbaandbeyond.com')
    key = 'a7d01ba1694928f372d155e28a1c6dab';
    amplitude.getInstance().init(key, undefined, 
      {
        saveEvents: true,
        includeUtm: true,
        includeReferrer: true,
        includeGclid: true
      }
    );
};

export const setAmplitudeUserDevice = (installationToken: string) => {
  if (!localStorage.getItem("isConsultant")) { 
    amplitude.getInstance().setDeviceId(installationToken);
  }
};

export const setAmplitudeUserId = (userId : string | null) => {
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties: any) => {
  if (!localStorage.getItem("isConsultant")) { 
    amplitude.getInstance().setUserProperties(properties);
  }
};

export const sendAmplitudeData = (eventType: any, eventProperties: any) => {
  if (!localStorage.getItem("isConsultant")) {
    amplitude.getInstance().logEvent(eventType, eventProperties);
  }
};
