/* eslint-disable @typescript-eslint/no-explicit-any */
import { apiUrls } from "../utils/apiUrls";
import axiosInstance from "../helpers/interceptor";

const getQuestionData = async (applicantId:any) => {
    const { data } = await axiosInstance.get(`${apiUrls.GET_INTERVIEW_PREP_DATA}?uid=${applicantId}`,);
    return data;
}

const uploadInterviewPrepVideo = async (videoData: any, questionData:any): Promise<void> => {
    const { questionId, questionName, applicantId, videoOrientation } = questionData;
    const questionDataString = `questionId=${questionId}&questionName=${questionName}&applicantId=${applicantId}&videoOrientation=${videoOrientation}`;
    const { data } = await axiosInstance.post(`${apiUrls.POST_INTERVIEW_PREP_DATA}?${questionDataString}`, videoData);
    return data;
};

export const interviewPrepService = {
uploadInterviewPrepVideo,
getQuestionData
};